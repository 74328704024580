$(() => {
  if (!$('#mypage-prs_new').length) {
    if (!$('#mypage-prs_create').length) {
      return
    }
  }

  let isValid = () => {
    let valid = true
    if (!$('#js-dm-from-request-form input:checked').length) { 
      valid = false
    }

    if (!$('#js-dm-from-request-form textarea').val().length) {
      valid = false
    }
    return valid
  }

  $('#js-dm-from-request-form-all-select').on('change', (e) => {
    let checked = $(e.target).prop('checked')
    $('#js-dm-from-request-form input').prop('checked', checked)
  })

  $('#js-dm-from-request-form input, #js-dm-from-request-form textarea').on('change', () => {
    if (isValid()) {
      $('button').prop('disabled', false)
    } else {
      $('button').prop('disabled', true)
    }
  })

})
