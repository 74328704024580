$(() => {
  if (!$('.horizontal-scroll').length) {
    return
  }

  let setup = (elm) => {
    let children = $(elm).children()
    let prev = `<div class='js-horizontal-scroll_prev horizontal-scroll-prev'></div>`
    let next = `<div class='js-horizontal-scroll_next horizontal-scroll-next'></div>`
    let width = $(elm).width()
    let childrenWidth = 0
    if (children.length > 0) {
      let childWidth = $(children[0]).width()
      childrenWidth = childWidth * children.length
    }
    if (width < childrenWidth) {
      $(elm).after(next)
      $(elm).after(prev)
    }
  }

  let movePrev = (elm) => {
    let children = $(elm).children()
    let currentLeft = $(elm).scrollLeft()
    for (let i = 0; i < children.length; i++) {
      let child = $(children[children.length - 1 - i])
      let left = $(child).position().left + currentLeft
      if (left < currentLeft) {
        $(elm).animate({
          scrollLeft: left
        }, "fast");
        return
      }
    }
  }

  let moveNext = (elm) => {
    let children = $(elm).children()
    let currentLeft = $(elm).scrollLeft()
    for (let i = 0; i < children.length; i++) {
      let child = $(children[i])
      let left = $(child).position().left + currentLeft
      if (left - 10 > currentLeft) {
        $(elm).animate({
          scrollLeft: left
        }, "fast");
        return
      }
    }
  }

  $(document).on('click', '.js-horizontal-scroll_prev', (e) => {
    let scroll = $(e.target).parent().find('.horizontal-scroll')
    movePrev(scroll)
  })

  $(document).on('click', '.js-horizontal-scroll_next', (e) => {
    let scroll = $(e.target).parent().find('.horizontal-scroll')
    moveNext(scroll)
  })

  $('.horizontal-scroll').each((i, element) => {
    setup(element)
  })
})