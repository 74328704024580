$(() => {
  if (!$('.admin-debugs').length) {
    return
  }

  // iframeIdを指定すると、特定のiframeのみで発火する
  let callback = (action, time, iframeId) => {
    if (!time) {
      time = 1000
    }
    setTimeout(() => {
      $('iframe').each((i, el) => {

        if (iframeId) {
          let id = $(el).attr('id')
          if (!id || !id.includes(iframeId)) {
            return
          }
        }

        console.log('action')

        // documents
        let doc = $(el).contents()
        action(doc, el)
      })
    }, time)
  }

  let dummyNum = () => {
    return 1000
  }

  let dummyDate = () => {
    return '2023-10-01'
  }

  let dummyEmail = () => {
    return Math.random().toString(32).substring(2) + "@debug.com"
  }

  let dummyUrl = () => {
    return "https://" + Math.random().toString(32).substring(2) + ".com"
  }

  let dummyText = () => {
    return "テストです"
  }

  let setFormValue = (doc) => {
    // password
    let password = "_1234Abc"
    doc.find('input[type="password"]').val(password)

    // email
    doc.find('input[type="email"]').val(dummyEmail())

    // number
    doc.find('input[type="number"]').val(dummyNum())

    // date
    doc.find('input[type="date"]').val(dummyDate())
    
    // text系
    doc.find('input[type="text"]').each((i, el) => {
      let rules = $(el).data('rules')

      if (rules.includes("url")) {
        $(el).val(dummyUrl())
      } else if (rules.includes("tel")) {
        $(el).val("08011112222")
      } else if (rules.includes("romaji")) {
        $(el).val("test")
      } else if (rules.includes("kana")) {
        $(el).val("テスト")
      } else {
        $(el).val(dummyText())
      }
    })

    // email
    doc.find('textarea').val(dummyText())

    // select
    doc.find('select').each((i, el) => {
      $(el).find("option:nth-child(2)").prop('selected',true);
    })

    // checkbox
    let checked = {}
    doc.find('input[type="checkbox"]').each((i, el) => {
      let name = $(el).attr('name') || "_"
      if (!checked[name]) {
        checked[name] = 1
      } else {
        checked[name] += 1
      }
      if (checked[name] < 3) {
        // 同じnameのチェックは3回まで
        $(el).prop('checked', true)
        return
      }
    })

    // checkbox
    let setCheckBox = (target) => {
      doc.find(target).each((i, el) => {
        $(el).find('.checkbox').each((j, ck) => {
          if (j < 3) {
            // チェックは3回まで
            $(ck).click()
          }
        })
      }) 
    }
    setCheckBox('.js-multiple-checkbox')
    setCheckBox('.js-profile-categories-input')
    setCheckBox('.js-multiple-area-select-modal')
  }

  let setClick = (doc, target) => {
    doc.find(target).click()
  }

  let setClickLink = (iframe, doc, target) => {
    let url = doc.find(target).attr('href')
    iframe.src = url
  }

  let setLocation = (iframe, url) => {
    iframe.src = url
  }

  let setScroll = (doc, target) => {
    const offset = 100;
    console.log(target)
    console.log(doc.find(target))
    const position = doc.find(target).offset().top;
    const speed = 200;
    $('body,html', doc ).animate({ scrollTop: position - offset}, speed);
  }

  /**
   * 2-3. 会員プロフィール(Seller)
   * 3-1. Item投稿
   * 4-1. Request投稿
   */
  if (!!$('.admin-debugs.edit_profile_seller').length
       || !!$('.admin-debugs.create_item').length
       || !!$('.admin-debugs.create_request').length) {
    callback((doc) => {
      setFormValue(doc)
    })
  }

  /**
   * 2-1. 会員登録
   */
  if (!!$('.admin-debugs.regist').length) {
    callback((doc) => {
      setFormValue(doc)
      setClick(doc, '#users-registrations_regist input[type="submit"]')

      // call
      callback((doc) => {
        setFormValue(doc)
      })
    })
  }

  /**
   * 5-1. PrivateRequest(Item経由)
   */
  if (!!$('.admin-debugs.create_private_request_from_item').length) {
    callback((doc, iframe) => {
      setScroll(doc, ".mc-item-sidebar")

      // callback
      callback((doc, iframe) => {
        doc.find('.mc-item-sidebar a').each((i, a) => {
          if ($(a).attr('href').includes("/mypage/requests/new")) {
            setLocation(iframe, $(a).attr('href'))

            // callback
            callback((doc, iframe) => {
              setFormValue(doc)
            })
          }
        })
      })
    }, 1000, "iframe-item")
  }
  /**
   * 4-2. RequestにProposal【Seller】
   * 5-2. PrivateRequest(Item経由 - Proposal)
   */
  if (!!$('.admin-debugs.create_proposal_for_private_request_from_item').length
      || !!$('.admin-debugs.create_proposal_for_public_request').length) {
    callback((doc) => {
      setScroll(doc, "#create-proposal-action-btn")

      // callback
      callback((doc, iframe) => {
        doc.find('#create-proposal-action-btn').each((i, a) => {
          if ($(a).attr('href').includes("/proposals/new")) {
            setLocation(iframe, $(a).attr('href'))

            // callback
            callback((doc) => {
              setFormValue(doc)
            })
          }
        })
      })
    })
  }
  /**
   * 5-3. ProposalにOrder【Buyer】
   */
  if (!!$('.admin-debugs.create_order_for_private_request_from_item').length) {
    callback((doc, iframe) => {
      setClickLink(iframe, doc, '.mc-create-order-from-proposal-action-btn')
      callback((doc, iframe) => {
        setFormValue(doc)
      })
    })
  }

  /**
   * 6-1. PrivateRequest(User経由)
   */
  if (!!$('.admin-debugs.create_private_request_from_user').length) {
    callback((doc, iframe) => {
      doc.find('a').each((i, a) => {
        if ($(a).attr('href').includes("/mypage/requests/new")) {
          setLocation(iframe, $(a).attr('href'))

          // callback
          callback((doc, iframe) => {
            setFormValue(doc)
          })
        }
      })
    }, 1000, "iframe-user")
  }
});