import Quill from 'quill';
import ImageUploader from "quill-image-uploader";

$(() => {
  if (!$('#admin-helps_new').length) {
    if (!$('#admin-helps_edit').length) {
      if (!$('#admin-helps_create').length) {
        if (!$('#admin-helps_update').length) {
          return
        }
      }
    }
  }

  Quill.register("modules/imageUploader", ImageUploader);

  let quill = new Quill('#js-editor', {
    modules: {
      toolbar: [
        [{ header: [1, 2, false] }],
        ['image'],
      ],
      imageUploader: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            let that = this
            let formData = new FormData()
            formData.append('image[image_file]', file)
            $.ajax({
              url: '/api/v1/images',
              type: 'POST',
              data: formData,
              dataType: 'json',
              processData: false,
              contentType: false,
            }).done((data) => {
              resolve(data.image_file.url)
            })
          });
        },
      },
    },
    placeholder: '本文を入力してください',
    theme: 'snow' 
  });
  quill.on('text-change', function() {
    $("#help_body").val(quill.root.innerHTML)
  });

});