$(() => {
  if (!$('#owner-site_spec_home_pickups_new').length) {
    if (!$('#owner-site_spec_home_pickups_edit').length) {
      if (!$('#owner-site_spec_home_pickups_create').length) {
        if (!$('#owner-site_spec_home_pickups_update').length) {
          return
        }
      }
    }
  }

  switchLinkFromArea = () => {
    let linkFormType = $('.js-link-form-type-switcher:checked').val()
    if (linkFormType == 'other') {
      $('.js-link-form-type-area-other').show()
      $('.js-link-form-type-area-other').removeClass('js-validate-skip')
      $('.js-link-form-type-area-special').hide()
      $('.js-link-form-type-area-special').addClass('js-validate-skip')
    }
    if (linkFormType == 'special') {
      $('.js-link-form-type-area-other').hide()
      $('.js-link-form-type-area-other').addClass('js-validate-skip')
      $('.js-link-form-type-area-special').show()
      $('.js-link-form-type-area-special').removeClass('js-validate-skip')
    }
  }

  $('.js-link-form-type-switcher').on('change', (e) => {
    switchLinkFromArea()
  });

  $('#site_spec_home_pickup_special_id').on('change', (e) => {
    let select = $(e.target)
    if (!select.val()) {
      return
    }
    let specials = select.data('specials')
    for (let i = 0; i < specials.length; i++) {
      let special = specials[i]
      if (special.id.toString() == select.val().toString()) {
        // 選択したやつ
        $('#site_spec_home_pickup_title').val(special.title)
        $('#site_spec_home_pickup_description').val(special.description)
        break
      }
    }
  })

  // init
  switchLinkFromArea()
});