$(() => {
  if (!$('.js-toggle-checkbox').length) {
    return
  }

  $('.js-toggle-checkbox').on('change', (e) => {
    target = $(e.target)
    checked = target.prop('checked')
    if (checked) {
      $(target.data('target')).show()
    } else {
      $(target.data('target')).hide()
    }

  })
})
