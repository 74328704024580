$(() => {
  if (!$('.mc-progress-circular').length) {
    return
  }

    $(".mc-progress-circular").each(function() {
  
      var value = $(this).attr('data-value');
      var left = $(this).find('.mc-progress-circular-left .mc-progress-circular-bar');
      var right = $(this).find('.mc-progress-circular-right .mc-progress-circular-bar');
  
      if (value > 0) {
        if (value <= 50) {
          right.css('transform', 'rotate(' + percentageToDegrees(value) + 'deg)')
        } else {
          right.css('transform', 'rotate(180deg)')
          left.css('transform', 'rotate(' + percentageToDegrees(value - 50) + 'deg)')
        }
      }
  
    })
  
    function percentageToDegrees(percentage) {
      return percentage / 100 * 360
    }
  
})
