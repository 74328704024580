import Vue from 'vue'
import moment from 'moment'

$(() => {
  if (!$('.js-mc-datepicker').length) {
    return
  }

  // data-type > 選択タイプ( multiple_select, single_select, period_select)
  // data-value > 表示用値
  // data-value-format > 表示用値の日付フォーマット(moment形式)
  // data-params > リクエスト値(hidden)
  // data-default > 初期データ(ex. 19901225)

  const setupComponent = (id, element) => {
   
    let type = $(element).data('type')
    let valueId = $(element).data('value')
    let valueFormat = $(element).data('value-format')
    let paramsId = $(element).data('params')
    let paramsFormat = $(element).data('params-format')
    let selectedDates = $(element).data('default')

    new Vue({
      el: id,
      data: {
        type: type,
        today: null,
        currentMonth: null,
        currentCalndar: [],
        nextMonth: null,
        nextCalndar: [],
        selectedDates: [],
        rules: $(id).data('rules'), // Validルール
        errors: [], // Validエラーメッセージ
      },
      mounted() {
        // 今月を設定
        let now = moment(new Date())
        this.today = this.formatDate(now.year(), now.month(), now.date())
        this.currentMonth = moment(new Date())
        this.currentCalndar = this.getCalendar(this.currentMonth)
        this.nextMonth = moment(new Date()).add(1, 'months')
        this.nextCalndar = this.getCalendar(this.nextMonth)

        // デフォルト選択
        if (selectedDates) {
          for (let date of selectedDates.toString().split(',')) {
            this.selectedDates.push(parseInt(date))
          }
          this.updateParams()
        }

        $('.js-form-validation .submit').on('click', (e) => {
          this.validForm()
        })
      },
      methods: {
        validForm() {
          if (!this.rules || this.rules.length == 0) {
            // 未定義はリターン
            return
          }
          this.errors = []
          if (!this.url || this.url == '') {
            this.errors.push("日付を選択してください")
          }

          $(id).find('input').trigger('change')
        },
        dispDate(val) {
          if (!val) {
            return null
          }
          return val % 100
        },
        dispValue(val) {
          let text = val.toString().slice(0, 4) + "-" + val.toString().slice(4);
          text = text.slice(0, 7) + "-" + text.slice(7);
          return moment(text).format(valueFormat)
            .replace('Monday', '月')
            .replace('Tuesday', '火')
            .replace('Wednesday', '水')
            .replace('Thursday', '木')
            .replace('Friday', '金')
            .replace('Saturday', '土')
            .replace('Sunday', '日')
            .replace('曜日', '')
        },
        dispParams(val) {
          let text = val.toString().slice(0, 4) + "-" + val.toString().slice(4);
          text = text.slice(0, 7) + "-" + text.slice(7);
          return moment(text).format(paramsFormat) 
        },
        dispClass(momentObj, val) {
          if (!val) {
            return 'blank'
          }
          if (this.selectedDates.includes(val)) {
            return "selected"
          }
          if (this.today == val) {
            return "today"
          }
          let currentVal = this.formatDate(momentObj.year(), momentObj.month(), momentObj.date())
          if (parseInt(currentVal / 100) != parseInt(val / 100)) {
            return 'text-muted'
          }
        },
        formatDate(year, month, date) {
          return year * 10000 + (month + 1) * 100 + date
        },
        getCalendar(momentObj)  {
          // 当月
          let now = momentObj.clone();
          let startDate = now.startOf('month').date()
          let startDay = now.startOf('month').day()
          let endDate = now.endOf('month').date()
          let endDay = now.endOf('month').day()
          let calendar = []
          for (let i = startDate; i <= endDate; i++) {
            calendar.push(this.formatDate(now.year(), now.month(), i))
          }
          // 前月
          let prevMonth = now.clone().subtract(1, 'months')
          let prevEndDate = prevMonth.endOf('month').date()
          if (startDay == 0) {
            // 日曜始まり
            for (let i = 0; i < 6; i ++) {
              // 日付ではなく空を入れる
              // calendar.unshift(this.formatDate(prevMonth.year(), prevMonth.month(), prevEndDate - i))
              calendar.unshift(null)
            }
          } else {
            for (let i = 0; i < (startDay - 1); i++) {
              // 日付ではなく空を入れる
              // calendar.unshift(this.formatDate(prevMonth.year(), prevMonth.month(), prevEndDate - i))
              calendar.unshift(null)
            }
          }
          // 翌月
          let nextMonth = now.clone().add(1, 'months')
          let nextStartDate = nextMonth.startOf('month').date()
          if (endDay == 0) {
            // 追加なし
          } else {
            for (let i = 0; i < (7 - endDay); i++) {
              // 日付ではなく空を入れる
              // calendar.push(this.formatDate(nextMonth.year(), nextMonth.month(), nextStartDate + i))
              calendar.push(null)
            }
          }

          return calendar
        },
        updateParams() {
          // 表示用
          let values = []
          let params = []
          for (let date of this.selectedDates) {
            values.push(this.dispValue(date))
            params.push(this.dispParams(date))
          }

          $(paramsId).val(params.join(','))

          //  表示日付
          if (values.length > 0) {
            $(`input${valueId}`).val(values.join(','))
            $(`div${valueId}`).html(`<table class="borderless"><tr><td class="pr-3 align-top" style="width:70px"><strong class="text-dark">選択中</strong></td><td>${values.join('　')}</td></tr></table>`)
          } else {
            $(`input${valueId}`).val('')
            $(`div${valueId}`).html('')
          }
          this.validForm()
        },
        onSelectDate(val) {
          if (this.type == 'single_select') {
            // シングルセレクトは1件だけ選択して閉じる
            this.selectedDates = []
            this.selectedDates.push(val)
            this.updateParams()
            $('.js-mc-datepicker').addClass('d-none')
            return
          }

          if (this.selectedDates.includes(val)) {
            // チェック済みは削除
            this.selectedDates.splice(
              this.selectedDates.indexOf(val), 1)
          } else {
            // 未チェックは追加
            this.selectedDates.push(val)
          }
          // ソート
          this.selectedDates.sort((a,b) => {
            if(a > b) return 1;
            if(a < b) return -1;
            return 0;
          });
          this.updateParams()
        },
        onPrevMonth() {
          this.currentMonth.subtract(1, 'months')
          this.currentCalndar = this.getCalendar(this.currentMonth)
          this.nextMonth.subtract(1, 'months')
          this.nextCalndar = this.getCalendar(this.nextMonth)
        },
        onNextMonth() {
          this.currentMonth.add(1, 'months')
          this.currentCalndar = this.getCalendar(this.currentMonth)
          this.nextMonth.add(1, 'months')
          this.nextCalndar = this.getCalendar(this.nextMonth)
        },
        onClearSelected() {
          this.selectedDates = []
          this.updateParams()
        }
      },
    })
  }

  // picker以外をタップしたら閉じる
  $(document).on('click', '.js-mc-datepicker', function (e) {
    if(!$(e.target).closest('.js-mc-datepicker_picker').length) {
      $('.js-mc-datepicker').addClass('d-none')
    }
  })
  $(document).on('click', '.js-mc-datepicker_close', function (e) {
      $('.js-mc-datepicker').addClass('d-none')
  })

  $('.js-mc-datepicker_input').each((i, element) => {
    let id = $(element).data('target')

    // pickerのポジション
    let onChangePickerPosition = () => {
      let picker = $(id + ' .js-mc-datepicker_picker')
      let offset = $(element).offset()
      let height = $(element).outerHeight()
      picker.offset(() => {
        return {
          top: offset.top + height + 8,
          left: offset.left,
        };
      });
    }
    $(window).scroll(function() {
      onChangePickerPosition()
    });
    $(window).resize(() => {
      onChangePickerPosition()
    });
    onChangePickerPosition()

    // Inputタップでpicker開く
    $(element).on('click', (e) => {
      $(id).removeClass('d-none')
      const elem = document.activeElement;
      elem.blur();
      onChangePickerPosition()
    })

    // pickerセットアップ
    setupComponent(id, element)
  })
})
