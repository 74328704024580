$(() => {
  if (!$('.js-object-search-form').length) {
    return
  }
  let form = $('.js-object-search-form')

  // $('.js-object-search-form').on('change', 'input, select, textarea', (e) => {
  //   form.submit()
  // })
})
