import Vue from 'vue'
import axios from 'axios'

$(() => {
  if (!$('#mypage-chats_show').length) {
    return
  }

  const id = '#js-mypage-chats-chat_messages'
  const chatId = $(id).data('chat-id')
  new Vue({
    el: id,
    data: {
      chatMessages: [],
      isLoading: true,
      isOldestMessageShow: false,
    },
    created: function() {
      this.setupChatMessages()
    },
    methods: {
      dispBody(chatMessage) {
        return chatMessage.body.replace(/\r\n|\r|\n/g, "<br/>")
          .replace(/((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g, "<a target='_blank' href='$1' >$1</a>")
      },
      // byteを表示用の変換
      formatBytes(bytes, decimals = 1)  {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      },
      // ChatMessageをセットする
      setupChatMessages() {
        let that = this
        this.getChatMessages({
          chat_id: chatId,
        }, (chatMessages) => {
          that.scrollToChatMessage(this.chatMessages[this.chatMessages.length - 1].id)
        })
      },
      onMoreChatMessages() {
        let beforeHeight = $('html').get(0).scrollHeight
        let beforeScroll = window.scrollY
        // 取得
        this.getChatMessages({
          chat_id: chatId,
          oldest_id: this.chatMessages[0].id
        }, () => {
          // // もっと見るを押した位置から動かさない
          // setTimeout(() => {
          //   let afterHeight = $('html').get(0).scrollHeight
          //   let diff = afterHeight - beforeHeight
          //   $(window).scrollTop(beforeScroll + diff, 1000)
          // }, 0)
        })
      },
      // ChatMessageの一覧を取得する
      getChatMessages(params, callback) {
        this.isLoading = true
        axios
          .get('/api/v1/chat_messages', {
            params: params
          })
          .then(resp => {
            this.isLoading = false
            // リスト処理
            for (let chatMessage of resp.data.chat_messages) {
              this.pushChatMessages(chatMessage)
            }
            callback && callback(resp.data.chat_messages)
          })
      },
      // メッセージをリストに追加
      pushChatMessages(newChatMessage) {
        // console.log(newChatMessage)
        if (newChatMessage.is_last) {
          // もっと見るを非表示に
          this.isOldestMessageShow = true
        }
        for(let i = 0; i < this.chatMessages.length; i++) {
          // 古いメッセージの場合
          if (this.chatMessages[i].id > newChatMessage.id) {
            this.chatMessages.splice(i, 0, newChatMessage)
            return
          }
        }
        // 最新のメッセージ
        this.chatMessages.push(newChatMessage) 
      },
      // 指定のメッセージまでスクロールする
      scrollToChatMessage(chatMessageId) {
        setTimeout(() => {
          let top = $(`#message-${chatMessageId}`).position().top
          $(window).scrollTop(top, 0)
        }, 0)
      } 
    },
  })
})
