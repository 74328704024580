$(() => {
  if (!$('#contacts_new').length) {
    return
  }

  let updateDefaultBody = () => {
    let self = $('#contact_contact_type')
    let selectedValue = self.val()
    let config = self.data('config')
    for (let conf of config) {
      if (conf[0] == selectedValue) {
        $("#contact_body").val(conf[2])
      }
    }
  }

  $("#contact_contact_type").on('change', (e) => {
    updateDefaultBody()  
  })
  
  updateDefaultBody()  
})
