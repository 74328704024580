$(() => {
  if (!$('.js-toast').length) {
    return
  }

  trigger = $('.js-toast')

  type = trigger.data('type')
  message = trigger.data('message')

  Toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-bottom-full-width',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '7000',
    extendedTimeOut: '0',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
  }
  Toastr[type](message)
})