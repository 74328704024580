
$(() => {
  if (!$('.js-movie-list').length) {
    return
  }

  const setupComponent = (element) => {
    let slicker = $(element).slick({
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 7000,
      fade: true,
      cssEase: 'linear'
    })
  }


  $('.js-movie-list').each((i, element) => {
    setupComponent(element)
  })
})
