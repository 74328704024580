import Quill from 'quill';
import ImageUploader from "quill-image-uploader";
import htmlEditButton from "quill-html-edit-button";
import Vue from 'vue'
import axios from 'axios'

$(() => {
  if (!$('#js-wysiwyg-editor').length) {
    return
  }
  // ImageUploader
  let Image = Quill.import('formats/image');
  Image.className = 'js-quill-image-to-change-inline';
  Quill.register(Image, true);
  Quill.register("modules/imageUploader", ImageUploader);

  // HTML Embed
  const BlockEmbed = Quill.import('blots/block/embed');
  class HtmlBlot extends BlockEmbed {
      static create (value) {
        let node = super.create();
          let html = document.createElement('span');
          html.innerHTML = value
          node.appendChild(html);
          return node;
      }
      static value(node) {
      return node.innerHTML
    }
    }
  HtmlBlot.blotName = 'html';
  HtmlBlot.tagName = 'span';
  Quill.register(HtmlBlot);

  //  HtmlEditButton
  Quill.register({
    "modules/htmlEditButton": htmlEditButton
  });


  let id = "#js-wysiwyg-editor"
  let editor = id + " #js-wysiwyg-editor-textarea"
  let value = id + " #js-wysiwyg-editor-value"
  let toolbar = id + " #js-wysiwyg-editor-toolbar"

  let quill = new Quill(editor, {
    modules: {
      toolbar: {
        container: toolbar,
      },
      htmlEditButton: {
        msg: " ",
        okText: "確定",
        cancelText: "閉じる",
        buttonHTML: '<i class="fal fa-code"></i>'
        // buttonHTML: "<span style='line-height: 16px'>t;/ &gt;</span>",
      },
      imageUploader: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            let that = this
            let formData = new FormData()
            formData.append('image[image_file]', file)
            $.ajax({
              url: '/api/v1/images',
              type: 'POST',
              data: formData,
              dataType: 'json',
              processData: false,
              contentType: false,
            }).done((data) => {
              resolve(data.image_file.url)
            })
          });
        },
      }
    },
    placeholder: '本文を入力してください',
    theme: 'snow'
  });

  // テキスト変更時のコールバック
  let onChangeEditor = () => {
    let html = quill.root.innerHTML
    // memo: あらかじめ画像に'js-quill-image-to-change-inline'を付与し、後からstyleに置換する
    // Imageを拡張したいが大変そうなので、一旦こちらの方法で対応
    html = html.replaceAll('class="js-quill-image-to-change-inline"', 'style="width:100%;max-width: 600px;"');
    $(value).val(html)
  }
  quill.on('text-change', function() {
    onChangeEditor()
  });
  // 初期化
  onChangeEditor()

  // 外部からの登録用にグローバル登録している
  window.insertWysiwygEmbed = (html) => {
    quill.insertEmbed(quill.getSelection(true).index, 'html', html)
  }

  // **************************
  // Item挿入
  // **************************
  $('#js-wysiwyg-editor-add-item').on('click', () => {
    $('#wysiwyg-editor-item-modal').modal('show')
  })

  if ($('#wysiwyg-editor-item-modal').length > 0) {
    new Vue({
      el: "#wysiwyg-editor-item-modal",
      data: {
        items: [],
        loading: true,
      },
      created() {
        this.getItems({}, (items) => {
          this.items = items
        })
      },
      methods: {
        // モーダルの検索をenter時
        onEnterSearch(e) {
          e.preventDefault()
          this.items = []
          const params = {
            keyword: $(e.target).val()
          }
          this.getItems(params, (items) => {
            this.items = items
          })
        },
        // Itemの一覧を取得する
        getItems(params, callback) {
          this.loading = true
          axios
            .get('/api/v1/items', {
              params: params
            })
            .then(resp => {
              this.loading = false
              callback(resp.data.items)
            })
        },
        // Itemを挿入する
        onAddItem(e, item) {
          if ($(e.target).prop('disabled')) {
            return
          }
          this.startBtnLoading(e)
          axios
            .get('/owner/campaign_mails/fetch_item_mail', {
              params: { item_id: item.id },
              responseType: 'document',
            })
            .then(resp => {
              this.stopBtnLoading(e)
              window.insertWysiwygEmbed($(resp.data.body).html())
            })
        },
        startBtnLoading (e) {
          let submitBtn = $(e.target)
          submitBtn.prop('disabled', true)
          submitBtn.addClass('disabled')
          submitBtn.text('')
          return submitBtn.append(
            '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
          )
        },
        stopBtnLoading(e) {
          let submitBtn = $(e.target)
          submitBtn.text("追加完了")
          setTimeout(() => {
            submitBtn.text("追加")
            submitBtn.prop('disabled', false)
            submitBtn.removeClass('disabled')
          }, 1500)
        }
      }
    })
  }

  // **************************
  // Request挿入
  // **************************
  $('#js-wysiwyg-editor-add-request').on('click', () => {
    $('#wysiwyg-editor-request-modal').modal('show')
  })

  if ($('#wysiwyg-editor-request-modal').length > 0) {
    new Vue({
      el: "#wysiwyg-editor-request-modal",
      data: {
        requests: [],
        loading: true,
      },
      created() {
        this.getRequests({}, (requests) => {
          this.requests = requests
        })
      },
      methods: {
        // モーダルの検索をenter時
        onEnterSearch(e) {
          e.preventDefault()
          this.requests = []
          const params = {
            keyword: $(e.target).val()
          }
          this.getRequests(params, (requests) => {
            this.requests = requests
          })
        },
        // Requestの一覧を取得する
        getRequests(params, callback) {
          this.loading = true
          axios
            .get('/api/v1/requests', {
              params: params
            })
            .then(resp => {
              this.loading = false
              callback(resp.data.requests)
            })
        },
        // Requestを挿入する
        onAddRequest(e, request) {
          if ($(e.target).prop('disabled')) {
            return
          }
          this.startBtnLoading(e)
          axios
            .get('/owner/campaign_mails/fetch_request_mail', {
              params: { request_id: request.id },
              responseType: 'document',
            })
            .then(resp => {
              this.stopBtnLoading(e)
              window.insertWysiwygEmbed($(resp.data.body).html())
            })
        },
        startBtnLoading (e) {
          let submitBtn = $(e.target)
          submitBtn.prop('disabled', true)
          submitBtn.addClass('disabled')
          submitBtn.text('')
          return submitBtn.append(
            '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
          )
        },
        stopBtnLoading(e) {
          let submitBtn = $(e.target)
          submitBtn.text("追加完了")
          setTimeout(() => {
            submitBtn.text("追加")
            submitBtn.prop('disabled', false)
            submitBtn.removeClass('disabled')
          }, 1500)
        }
      }
    })
  }
})
