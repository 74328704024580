$(() => {
  if (!$('.js-copy').length) {
    return
  }

  $(".js-copy").on('click', function(e) {
    let target = $(this)
    let text = target.data('text')

    if(navigator.clipboard == undefined) {
      window.clipboardData.setData('Text', text);
    } else {
      navigator.clipboard.writeText(text);
    }

    target.tooltip({
      title: "URLをコピーしました！",
      delay: { "hide": 300 }
    })
    target.tooltip('enable')
    target.tooltip('show')
    target.tooltip('disable')
  })
})
