import Vue from 'vue'

$(() => {
  if (!$('#orders_new').length) {
    return
  }

  if ($('#js-order-form').length > 0) {
    const id = '#js-order-form'
    new Vue({
      el: id,
      data: {
        payment_method: [],
      },
      methods: {
        onTapOption(id) {
          let index = this.selectOptions.findIndex((o) => o.id === id)
          if (-1 < index) {
            this.selectOptions.splice(index, 1)
          } else {
            let option = this.options.find((o) => o.id == id)
            this.selectOptions.push(option)
          }
        }
      },
    })
  }
});