$(() => {
  if (!$('.js-tags-input').length) {
    return
  }

  let setupComponent = (id) => {
    $(id).tagsinput({
      maxTags: $(id).data('max-tag')
    });
  }

  $('.js-tags-input').each((i, element) => {
    const id = $(element).attr('id')
    setupComponent('#' + id)
  })
})
