import Vue from 'vue'

$(() => {
  if (!$('.js-tag-select-modal').length) {
    return
  }

  // memo
  // .js-tag-select-modal > モーダル
  // .js-tag-select-modal_wrapper > wrapper
  // .js-tag-select-modal_open> モーダルを開く
  // .js-tag-select-modal_value > エリア選択された値を表示するinput
  // .js-tag-select-modal_params > エリア選択された値のhidden input

  const setupComponent = (id) => {

    const wrapper  = $(id).closest('.js-tag-select-modal_wrapper')
    const opener = wrapper.find('.js-tag-select-modal_open')
    const valueInput = wrapper.find('.js-tag-select-modal_value')

    // // モーダル表示イベント
    opener.on('click', (e) => {
      $(id).modal('show')
    })
  }


  // 初期化
  $('.js-tag-select-modal').each((i, element) => {
    let id = $(element).attr('id')
    setupComponent('#' + id)
  })
})
