import Vue from 'vue'

$(() => {
  if (!$('.js-hierarchy-checkbox').length) {
    return
  }

  const hierarchyCheckBox = (id) => {
    new Vue({
      el: id,
      data: {
        rules: $(id).data('rules'), // Validルール
        selected: $(id).data('selected'),
        selectedChoice: 0,
        choices: $(id).data('choices'), // SiteChoicePart.search_under_groupの値
        errors: [], // Validエラーメッセージ
      },
      mounted() {
        $('.js-form-validation .submit').on('click', (e) => {
          this.validForm()
        })
      },
      methods: {
        dispSelectedLabel() {
          if (this.selected.length == 0) {
            return '選択中のものがありません'
          }
          let labels = []
          for (let choice of this.choices) {
            for (let choicePart of choice.parts) {
              if (this.selected.includes(choicePart.id)) {
                labels.push(choicePart.name)
              }
            }
          }
          return labels.join('、')
        },
        // choice内のパートすべてにチェックが入ってるか
        dispIsSelectedAllPart(choice) {
          for (let part of choice.parts) {
            if (!this.selected.includes(part.id)) {
              return false
            }
          }
          return true
        },
        dispFormValue() {
          // [1,2] → ["1", "2"]
          let value = [] 
          for (let select of this.selected) {
            value.push(new String(select))
          }
          return JSON.stringify(value)
        },
        validForm() {
          this.errors = []
          if (this.selected.length > 30) {
            this.errors.push(`30件以内で選択してください（選択中：${this.selected.length}件}`)
          }
          if (!this.rules || this.rules.length == 0) {
            // 未定義はリターン
            return
          }
          if (this.rules.indexOf('presence') != -1) {
            if (this.selected.length == 0) {
              this.errors.push("少なくとも1件以上選択してください")
            }
          }
          if (this.rules.indexOf('limit') != -1) {
            if (this.selected.length > $(id).data('limit')) {
              this.errors.push(`${$(id).data('limit')}件以内で選択してください（選択中：${this.selected.length}件}`)
            }
          }
        },
        onSelectAllPart(choice) {
          if (this.dispIsSelectedAllPart(choice)) {
            // 既に選択済みの場合はすべてチェック外す
            for (let part of choice.parts) {
              this.selected.splice(this.selected.indexOf(part.id), 1)
            }
            this.validForm()
            return
          }

          // 未選択の場合

          for (let part of choice.parts) {
            if (this.selected.includes(part.id)) {
              // チェック済みはスキップ
            } else {
              // 未チェックは追加
              this.selected.push(part.id)
            }
          }

          // 最後にソート
          this.selected.sort((a,b) => {
              if(a.sort > b.sort) return -1;
              if(a.sort < b.sort) return 1;
              return 0;
          });
          
          this.validForm()
        },
        onSelectChoice(index) {
          this.selectedChoice = index
        },
        onSelectChoicePart(choicePart) {
          if (this.selected.includes(choicePart.id)) {
            // チェック済みは削除
            this.selected.splice(
              this.selected.indexOf(choicePart.id), 1)
          } else {
            // 未チェックは追加
            this.selected.push(choicePart.id)
            this.selected.sort((a,b) => {
                if(a.sort > b.sort) return -1;
                if(a.sort < b.sort) return 1;
                return 0;
            });
          }
          this.validForm()
        },
      },
    })
  }

  $('.js-hierarchy-checkbox').each((i, element) => {
    const id = $(element).attr('id')
    hierarchyCheckBox('#' + id)
  })
})
