import moment from 'moment'

$(() => {
  if (!$('#mypage-items_new').length) {
    if (!$('#mypage-items_edit').length) {
      if (!$('#mypage-items_create').length) {
        if (!$('#mypage-items_update').length) {
          return
        }
      }
    }
  }

  $("#item_evented_from_at").on('change', (e) => {
    const dateFromat = "YYYY-MM-DDTHH:mm"
    const dateFromatTime0 = "YYYY-MM-DDT00:00"
    let val = $(e.target).val()
    if (!!val) {
      let date = moment(val, dateFromat)
      // イベント終了：翌日をセット
      $("#item_evented_to_at").val(moment(date).add(1, "days").format(dateFromatTime0))
      // 募集期間終了：前日をセット
      $("#item_orderable_to_at").val(moment(date).add(-1, "days").format(dateFromatTime0))
    }
  })
  
});