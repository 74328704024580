$(() => {
  if (!$('#owner-campaign_mails_new').length) {
    if (!$('#owner-campaign_mails_edit').length) {
      return
    }
  }

  let startBtnLoading = (e) => {
    let submitBtn = $(e.target)
    submitBtn.prop('disabled', true)
    submitBtn.addClass('disabled')
    submitBtn.text('')
    return submitBtn.append(
      '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    )
  }

  let stopBtnLoading = (e) => {
    let submitBtn = $(e.target)
    submitBtn.text("送信が完了しました")
    setTimeout(() => {
      submitBtn.text("テストメールを送信")
      submitBtn.prop('disabled', false)
      submitBtn.removeClass('disabled')
    }, 1500)
  }  

  $('.js-send-test-mail').on('click', (e) => {
    startBtnLoading(e)
    let that = this
    let formData = new FormData()
    formData.append('to', $('#campaign_mail_to').val())
    formData.append('subject', $('#campaign_mail_subject').val())
    formData.append('body', $('#js-wysiwyg-editor-value').val())
    $.ajax({
      url: '/owner/campaign_mails/send_test_mail',
      type: 'POST',
      data: formData,
      dataType: 'json',
      processData: false,
      contentType: false,
    }).done((data) => {
      stopBtnLoading(e)
    }) 
  })
})