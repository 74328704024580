$(() => {
  if (!$('[data-toggle="slide-modal"]').length) {
    return
  }

  let setupComponent = (element) => {
    let modal_id = $(element).data('target')
    let modal = $(modal_id)

    $(element).on('click', ()=> {
      $("body").addClass('modal-open')
      $("body").append('<div class="modal-backdrop fade show" ></div>')
      $(modal).addClass('open')
    })

    $('[data-dismiss="slide-modal"]').on('click', () => {
      $('.modal-backdrop').hide()
      $('body').removeClass('modal-open')
      $(modal).removeClass('open') 
    })
  }

   // 初期化
   $('[data-toggle="slide-modal"]').each((i, element) => {
    setupComponent(element)
  })
})