$(() => {
  if (!$('#admin-spec_editors_edit').length) {
    return
  }

  $('.js-json-update').on('click', () => {
    let formData = new FormData()
    formData.append('spec', $('[name="spec"]').val())
    formData.append('col', $('[name="col"]').val())
    formData.append('json', $('[name="json"]').val())

    $.ajax({
      url: `/admin/sites/${$('.js-json-update').data('site-id')}/spec_editors`,
      type: 'POST',
      dataType: 'json',
      data: formData,
      processData: false,
      contentType: false,
    }).done((data) => {
      $('.js-json-update').text('更新しました')
      setTimeout(() => {
        $('.js-json-update').text('保存する')
      }, 3000)
    }).fail(function (msg) {
      $('.js-json-update').text('更新に失敗しました')
      setTimeout(() => {
        $('.js-json-update').text('保存する')
      }, 3000)
    })
  })
});