import Vue from 'vue'

$(() => {
  if (!$('.js-sns-urls-input').length) {
    return
  }
  const setupComponent = (id) => {

    new Vue({
      el: id,
      data: {
        youtubeUrl: "",
        instagramUrl: "",
        facebookUrl: "",
        twitterUrl: "",
        noteUrl: "",
        githubUrl: "",
        urls: ""
      },
      mounted() {
        let defaultUrls = $(id).data('default')
        if (!!defaultUrls) {
          this.youtubeUrl = defaultUrls.youtube
          this.instagramUrl = defaultUrls.instagram
          this.facebookUrl = defaultUrls.facebook
          this.twitterUrl = defaultUrls.twitter
          this.noteUrl = defaultUrls.note
          this.githubUrl = defaultUrls.github
        }
        this.onUpdateParams()
      },
      methods: {
        onUpdateParams() {
          this.urls = JSON.stringify({
            youtube: this.youtubeUrl,
            instagram: this.instagramUrl,
            facebook: this.facebookUrl,
            twitter: this.twitterUrl,
            note: this.noteUrl,
            github: this.githubUrl,
          })
        },
      }
    })
  }


  // 初期化
  $('.js-sns-urls-input').each((i, element) => {
    let id = $(element).attr('id')
    setupComponent('#' + id)
  })
})
