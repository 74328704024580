$(() => {
  if (!$('.js-password-input').length) {
    return
  }

    $('.js-password-input').before('<i class="fal fa-eye-slash js-password-input_eye mc-password-input"></i>')

  $(document).on('click', '.js-password-input_eye', (e) => {
    let eye = $(e.target)
    eye.toggleClass('fa-eye fa-eye-slash');
    let input = eye.parent().find('.js-password-input')
    if (input.attr('type') == 'text') {
      input.attr('type','password');
    } else {
      input.attr('type','text');
    }
  })
})
