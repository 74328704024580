$(() => {
  if (!$('.js-show-all').length) {
    return
  }

  $('.js-show-all').on('click', (e) => {
    let self = $(e.target)
    let target = self.data('target')
    $(self).hide()
    $(target).show()
    $(target).removeClass('d-none')
  })
})
