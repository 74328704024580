$(() => {
  if (!$('.js-datetime-clear').length) {
    return
  }

  let setDateTimeClear = (e) => {
    $(e).parent().append('<div class="datetime-clear"><span class="js-datetime-local-clear">日付をリセット</span></div>')
  }

  $(document).on('click', '.js-datetime-local-clear', (e) => {
    let input = $(e.target).parent().parent().find('.js-datetime-clear')
    input.val(null)

    input.trigger('change')
  })

  $('.js-datetime-clear').each((i, e) => {
    setDateTimeClear(e)
  })
})
