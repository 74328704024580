$(() => {
  // 初期化処理
  $(".js-crawler-trigger-input").each(function () {
    const $input = $(this);

    // ボタンを追加
    const $button = $("<button>")
      .addClass("js-crawler-search-btn btn mc-btn-primary f11 py-1 px-3")
      .css('margin-top','4px')
      .css('float','right')
      .attr("type", "button")
      .html('<i class="fal fa-search"></i><span class="ml-1">URLを検索</span>')
      .insertAfter($input); // 入力フィールドの直後に追加
      // .text("URLを検索")
  });

  // ボタン押下時の処理
  $(document).on("click", ".js-crawler-search-btn", function () {
    const $button = $(this);
    const $input = $button.prev(".js-crawler-trigger-input"); // ボタンの直前の入力フィールドを取得
    const url = $input.val(); // 入力されたURLを取得

    if (!url) {
      alert("URLを入力してください");
      return;
    }

    // data-crawer属性を取得し、マッピングルールを解析
    const crawlerRules = $input.data("crawer");
    if (!crawlerRules) {
      alert("data-crawer属性が設定されていません");
      return;
    }

    // name属性のプレフィックスを動的に取得
    const namePrefix = extractNamePrefix($input.attr("name"));
    if (!namePrefix) {
      alert("name属性が不正です");
      return;
    }

    // ボタンを非活性化し、文言を「検索中」にする
    $button.prop("disabled", true).text("検索中...");

    // APIリクエストを送信
    $.ajax({
      url: "/api/v1/crawlers",
      method: "GET",
      data: { url: url },
      dataType: "json",
      success: function (response) {
        // レスポンスをフィールドにマッピング
        $.each(crawlerRules, function (key, fieldName) {
          if (response[key] !== undefined) {
            // 動的にname属性を生成して値を設定
            const targetField = $(`input[name="${namePrefix}[${fieldName}]"], textarea[name="${namePrefix}[${fieldName}]"]`);
            if (targetField.length > 0) {
              targetField.val(response[key]); // 値をセット
            } else {
              console.warn(`対応するフィールドが見つかりません: ${namePrefix}[${fieldName}]`);
            }
          }
        });
      },
      error: function (xhr, status, error) {
        alert("データの取得に失敗しました");
      },
      complete: function () {
        // ボタンを再活性化し、文言を戻す
        $button.prop("disabled", false)
          .html('<i class="fal fa-search"></i><span class="ml-1">URLを検索</span>');
      },
    });
  });

  /**
   * name属性からプレフィックスを抽出する関数
   * @param {string} name - フィールドのname属性
   * @returns {string} プレフィックス部分（例: user[profile_parts_attributes][0]）
   */
  function extractNamePrefix(name) {
    if (!name) return null;
    const match = name.match(/^(.*)\[[^\[\]]+\]$/); // 最後のキー部分を除去
    return match ? match[1] : null;
  }
});
