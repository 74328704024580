$(() => {
  if (!$('.js-form-comment').length) {
    return
  }

  const setComment = (el) => {
    let label = $(el)
    let comment = $(el).data('comment')
    label.append(`<span class="mc-form-comment ml-2" data-toggle="tooltip" data-placement="top" title="${comment}"><i class="fal fa-question-circle mc-secondary" /></span>`)
  }

  $('.js-form-comment').each((i, el) => {
    setComment(el)
  })
})
