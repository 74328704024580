import Vue from 'vue'
import axios from 'axios'

$(() => {
  if (!$('#admin-cs-sites_index').length) {
    return
  }

  const id = '#js-sites-form'
  new Vue({
    el: id,
    data: {
      sites: $(id).data('sites'),
      allSites: $(id).data('sites'),
      form: {
        isInputing: false,
        keyword: $(id).data('keyword'),
        siteCode: $(id).data('site-code'),
      }
    },
    created() {
    },
    methods: {
      // *************************
      // Event
      // *************************
      // keywordにフォーカス
      onFocusKeyword() {
        this.form.isInputing = true
      },
      onBlurKeyword() {
        setTimeout(() => {
          this.form.isInputing = false
        }, 300)
      },
      onChangeKeyword() {
        setTimeout(() => {
          if (!this.form.keyword) {
            this.sites = this.allSites
            return
          }

          this.sites = []
          let keyword = this.form.keyword.toUpperCase()
          this.allSites.forEach((site) => {
            if (site.name.toUpperCase().includes(keyword) || site.site_code.toUpperCase().includes(keyword))
            this.sites.push(site)
          })
        })
      },
      onSelectSite(site) {
        this.form.keyword = site.name
        this.form.siteCode = site.site_code
      },
    },
  })

  // iframe表示
  if ($('.js-admin-page-iframe').length > 0) {
    $('.js-admin-page-iframe iframe').each((i, elm) => {
      let delayTime = $(elm).data('delay')
      setTimeout(() => {
        let src_data = $(elm).attr("src_data");
        $(elm).attr("src", src_data);
        $(elm).removeAttr("src_data");
      }, delayTime)
    })
    return
  }
})