$(() => {
  if (!$('.js-boolean-and-text').length) {
    return
  }

  const setupComponent = (id, element) => {
    const parent = $('#' + id)
    const radios = $('#' + id + ' input[type=radio]')
    const textInput = parent.find('.js-boolean-and-text_text-input')
    const valueInput = parent.find('.js-boolean-and-text_value')
    const lastId = parent.data('last-id')

    const currentChecked = () => {
      return parent.find('input:checked').val()
    }

    const currentTextShow = () => {
      return (parent.find('input:checked').val() == lastId)
    }

    // text inputのスイッチ
    const toggleTextInput = () => {
      if (currentTextShow()) {
        textInput.show()
      } else {
        textInput.hide()
      }
    }

    // 値のセット
    const setValue = () => {
      let text = currentTextShow() ? textInput.val() : null
      valueInput.val(JSON.stringify([
        currentChecked(),
        (text ? text : '')
      ]))
    }

    radios.on('change', (e) => {
      toggleTextInput()
      setValue()
    })

    textInput.on('change', (e) => {
      setValue()
    })

    toggleTextInput()
    setValue()
  }

  $('.js-boolean-and-text').each((i, element) => {
    let id = $(element).attr('id')
    setupComponent(id, element)
  })
})
