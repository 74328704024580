$(() => {
  if (!$('.js-range-search-tooltip').length) {
    return;
  }

  const $displayInput = $(".js-range-search-tooltip-trigger");
  const $tooltip = $(".mc-range-search-tooltip");
  const $fromInput = $(".js-range-search-tooltip-from");
  const $toInput = $(".js-range-search-tooltip-to");

  // Display tooltip when displayInput gets focus
  $displayInput.on("focus", function () {
    $tooltip.show();
    $fromInput.focus();
  });

  // Hide tooltip when clicking outside
  $(document).on("click", function (event) {
    if (!$(event.target).closest(".js-range-search-tooltip").length) {
      $tooltip.hide();
    }
  });

  // Reflect from~to values in displayInput
  const updateDisplayInput = () => {
    const fromValue = $fromInput.val().trim();
    const toValue = $toInput.val().trim();
    if ((fromValue == undefined || fromValue == '') && (toValue == undefined || toValue == '')) {
      $displayInput.val('');
    } else {
      $displayInput.val(`${fromValue} 〜 ${toValue}`);
    }
  };

  // Update displayInput when from or to input changes
  $fromInput.on("input", updateDisplayInput);
  $toInput.on("input", updateDisplayInput);
});
