$(() => {
  if (!$('.js-price-input_hint').length) {
    return
  }

  // 大カテゴリを選択
  $('#site_category_group_id').on('change', () => {
    updatePriceForm()
  })

  // 小カテゴリを選択
  $('#site_category_id').on('change', () => {
    updatePriceForm()
  })

  // 価格の更新
  updatePriceForm = () => {
    const categoryId = $('#site_category_id').val()
    if (!categoryId) {
      // 値が空
      disablePriceForm()
    } else {
      // 値が入っている
      updatePriceFormOption(parseInt(categoryId))
    }
  }

  // price formをdisableにする
  disablePriceForm = () => {
    select = $('.js-price-input')
    select.empty()
    select.append(`<option value=''>カテゴリを選択してください</option>`)
    select.attr('disabled', true)
    // 最低価格表記
    priceHint = $('.js-price-input_hint')
    priceHint.hide()
  }

  // price formのoptionを更新
  updatePriceFormOption = (categoryId) => {
    groups = $('#site_category_id').data('categories')
    for (let i = 0; i < groups.length; i++) {
      group = groups[i]
      for (let j = 0; j < group.categories.length; j++) {
        category = group.categories[j]
        if (category.id === categoryId) {
          // 対象カテゴリーの場合の処理
          minPrice = category.min_price
          marketPrice = category.market_price
          // セレクトを初期化
          select = $('.js-price-input')
          if (!!select.length) {
            select.attr('disabled', false)
            select.empty()
            options = $('.js-price-input').data('default')
            options.map((option) => {
              label = option[0]
              price = option[1]
              if (price >= minPrice) {
                select.append(`<option value="${price}">${label}</option>`)
              }
            })
          }
          // 最低価格表記
          priceHint = $('.js-price-input_hint')
          if (!marketPrice && !minPrice) {
            // なければ非表示
            priceHint.hide()
            return
          } else {
            priceHint.show()
          }
         
          if (!marketPrice) {
            priceHint.find('.js-price-input_hint_market-price').hide()
          } else {
            priceHint.find('.js-price-input_hint_market-price').show()
            priceHint.find('.js-price-input_hint_market-price_value').text(marketPrice)
          } 

          if (!minPrice) {
            priceHint.find('.js-price-input_hint_min-price').hide()
          } else {
            priceHint.find('.js-price-input_hint_min-price').show()
            priceHint.find('.js-price-input_hint_min-price_value').text(minPrice + '円')
          } 
          return;
        }
      }
    }
  }

  // price formを初期化
  initPriceForm = () => {
    updatePriceForm()
    const categoryId = $('#site_category_id').val()
    if (!!categoryId) {
      // 値が入っている
      select = $('.js-price-input')
      select.val(select.data('value'))
    }
  }

  initPriceForm()
})
