import Chart from 'chart.js/auto'
import BlockConnect from '../../common/block-connect'

$(() => {
  if (!$('#owner-site_reports_show').length) {
    return
  }

  // utils
  let ceil = (num) => {
    if (num < 10) {
      return 10
    }
    let length = String(num).length 
    return Math.ceil(num / (10 ** (length - 2))) * (10 ** (length - 2))
  }

  let draw = () => {
    let canvasParent = document.getElementById('primary-table-parent')
    let canvas = document.getElementById('primary-table')
    canvas.width = canvasParent.clientWidth
    canvas.height = canvasParent.clientHeight
    new BlockConnect(canvas, 'new-user', 'total-user')
    new BlockConnect(canvas, 'repeat-user', 'total-user')
    new BlockConnect(canvas, 'move-detail-per-total-user', 'move-detail-user')
    new BlockConnect(canvas, 'total-user', 'move-detail-user')
    new BlockConnect(canvas, 'move-detail-user', 'cv-user')
    new BlockConnect(canvas, 'cv-per-move-detail-user', 'cv-user')

    new BlockConnect(canvas, 'new-item', 'total-item')
    new BlockConnect(canvas, 'repeat-item', 'total-item')
    new BlockConnect(canvas, 'cv-per-total-item', 'cv-item')
    new BlockConnect(canvas, 'total-item', 'cv-item')

    new BlockConnect(canvas, 'new-request', 'total-request')
    new BlockConnect(canvas, 'repeat-request', 'total-request')
    new BlockConnect(canvas, 'total-request', 'proposaled-total-request')
    new BlockConnect(canvas, 'proposaled-per-total-request', 'proposaled-total-request')
    new BlockConnect(canvas, 'proposaled-total-request', 'cv-request')
    new BlockConnect(canvas, 'cv-per-total-request', 'cv-request')

  }
  draw()

  // マッチング件数
  let orderCount = $('#order-count')
  let labels = orderCount.data('labels')
  let datas = orderCount.data('datas')
  let orderBackgroundColors = []
  for (let i = 0; i < labels.length; i++) {
    if (i == labels.length - 1) {
      orderBackgroundColors.push( 'rgba(101, 179, 224, 0.9)')
    } else {
      orderBackgroundColors.push( 'rgba(101, 179, 224, 0.3)')
    }
  }
  new Chart(orderCount, {
    type: 'bar',
    data: {
        labels: labels,
        datasets: [{
            label: 'マッチング件数',
            data: datas,
            backgroundColor: orderBackgroundColors,
        },
      ]
    },
    options: {
      plugins: {
        legend: {
          display: false
        },
      },
      scales: {
          x: {
              stacked: true,
              grid: {
                display: false
              }
          },
          y: {
              stacked: true,
              max: ceil(datas.reduce((a,b)=>Math.max(a,b))),
              grid: {
                borderDash: [8, 4]
              },
              ticks: {
                maxTicksLimit: 6,
            },
          }
      }
    }
  })

  // PV
  let pv = $('#pv')
  let pvLabels = pv.data('labels')
  let pvDatas = pv.data('datas')
  let pvBackgroundColors = []
  for (let i = 0; i < pvLabels.length; i++) {
    if (i == pvLabels.length - 1) {
      pvBackgroundColors.push( 'rgba(52, 204, 204, 0.9)')
    } else {
      pvBackgroundColors.push( 'rgba(52, 204, 204, 0.3)')
    }
  }
  new Chart(pv, {
    type: 'bar',
    data: {
        labels: pvLabels,
        datasets: [{
            label: 'PV数',
            data: pvDatas,
            backgroundColor: pvBackgroundColors,
        },
      ]
    },
    options: {
      plugins: {
        legend: {
          display: false
        },
      },
      scales: {
          x: {
              stacked: true,
              grid: {
                display: false
              }
          },
          y: {
              stacked: true,
              max: ceil(pvDatas.reduce((a,b)=>Math.max(a,b))),
              grid: {
                borderDash: [8, 4]
              },
              ticks: {
                maxTicksLimit: 6,
            },
          }
      }
    }
  })
})