import Cropper from 'cropperjs'
import { Modal } from 'bootstrap'

$(() => {
  if (!$('.js-icon-file-input').length) {
    return
  }

  // リサイズ
  const resizeImage = ({ file, mimeType, inputElement, outputElement, maxWidth, maxHeight }) => {
    if (!file || !inputElement || !outputElement) {
      return
    }
    if (!maxWidth && !maxHeight) {
      return
    }

    const img = new Image()
    img.src = file
    img.onload = () => {
      let width = img.width
      let height = img.height

      if (maxWidth && width > maxWidth) {
        height = Math.round(height * maxWidth / width)
        width = maxWidth
      }
      if (maxHeight && height > maxHeight) {
        width = Math.round(width * maxHeight / height)
        height = maxHeight
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      const base64File = canvas.toDataURL(mimeType)
      outputElement.val(base64File)
      inputElement.val('')

      const croppableElements = [
        '#js-icon-file-input-modal',
        '#crop_x-js-icon-file-input',
        '#crop_y-js-icon-file-input',
        '#crop_w-js-icon-file-input',
        '#crop_h-js-icon-file-input'
      ]
      const croppable = $(croppableElements.join(', ')).length == croppableElements.length
      if (croppable) {
        // canvasをクリア
        const wrapperId = "#js-icon-file-input-canvas-wrapper"
        $(wrapperId).empty()
        // canvasをセット
        $('<img>').attr({
            id: 'js-icon-file-input-canvas',
            src: base64File
        }).appendTo(wrapperId)
        initCrop(inputElement, outputElement)
        // modalを表示
        $('#js-icon-file-input-modal').modal('show')
      }
    }
  }

  $('.js-icon-file-input_input').on('change',  (e) => {
    const self = $(e.target)
    const parent = self.closest('.js-icon-file-input')
    const previewImage = parent.find('.js-icon-file-input_image')
    const previewDefaultImage = parent.find('.js-icon-file-input_default')
    const removeInput = parent.find('.js-icon-file-input_remove')
    const hiddenInput = parent.find('.js-icon-file-input_hidden')
    const maxWidth = self.data('max-width')
    const maxHeight = self.data('max-height')
    const mimeType = e.target.files[0].type

    const reader = new FileReader()
    reader.onload = (e) => {
      const file = e.target.result
      // プレビューをセット
      previewImage.attr('src', file)
      // プレビューの表示
      previewImage.show()
      previewDefaultImage.hide()
      // 削除パラメータをリセット
      removeInput.val('0')
      // リサイズ
      resizeImage({ file: file, mimeType: mimeType, inputElement: self, outputElement: hiddenInput, maxWidth: maxWidth, maxHeight: maxHeight })
    }
    reader.readAsDataURL(e.target.files[0]);
  });

  $('.js-icon-file-input_preview_image-delete').on('click', (e) => {
    const parent = $(e.target).closest('.js-icon-file-input')
    const previewImage = parent.find('.js-icon-file-input_image')
    const previewDefaultImage = parent.find('.js-icon-file-input_default')
    const removeInput = parent.find('.js-icon-file-input_remove')
    const hiddenInput = parent.find('.js-icon-file-input_hidden')

    // プレビューをリセット
    previewImage.hide()
    previewDefaultImage.show()
    // 削除パラメータをセット
    removeInput.val('1')
    // Base64画像をリセット
    if (hiddenInput.length) {
      hiddenInput.val('')
    }
  })

  let cropper = null;
  const initCrop = function(inputElement, outputElement) {
    const cropImage = document.getElementById('js-icon-file-input-canvas');
    cropper = new Cropper(cropImage, {
      dragMode: 'move',
      guides: false,
      highlight: false,
      aspectRatio: 1,
      minContainerHeight: 350,
      minContainerWidth: 400,
      crop: function(e) {
        const d = e.detail;
        $('#crop_x-js-icon-file-input').val(d.x);
        $('#crop_y-js-icon-file-input').val(d.y);
        $('#crop_w-js-icon-file-input').val(d.width);
        return $('#crop_h-js-icon-file-input').val(d.height);
      }
    });
    cropImage.addEventListener('ready', function(e) {
      cropping(inputElement, outputElement);
    });
    cropImage.addEventListener('cropend', function(e) {
      cropping(inputElement, outputElement);
    });
    return cropImage.addEventListener('zoom', function(e) {
      cropping(inputElement, outputElement);
    });
  };

  const cropping = function(inputElement, outputElement) {
    const imageElement = $(inputElement).closest('.js-icon-file-input').find('img.js-icon-file-input_image')
    const croppedCanvas = cropper.getCroppedCanvas()
    const base64File = croppedCanvas.toDataURL()
    imageElement.prop('src', base64File)
    $(outputElement).val(base64File)
  };
})
