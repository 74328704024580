import Vue from 'vue'
import draggable from 'vuedraggable'

$(() => {
  if (!$('.js-list-input').length) {
    return
  }

  const setupComponent = (id) => {
    new Vue({
      el: id,
      components: {
        'draggable': draggable,
      },
      data: {
        rules: $(id).data('rules'), // Validルール
        values: $(id).data('values'),
        form: "",
        errors: [], // Validエラーメッセージ
      },
      mounted() {
        if (!this.values) {
          this.values = []
        }
        this.form = "[" + this.values.map(v => ("\"" + v + "\"") ).join(',') + "]"
        $('.js-form-validation .submit').on('click', (e) => {
          this.validForm()
        })
      },
      methods: {
        validForm() {
          if (!this.rules || this.rules.length == 0) {
            // 未定義はリターン
            return
          }
          this.errors = []
          if (this.values.length == 0) {
            this.errors.push("少なくとも1件以上入力してください")
          }

          $(id).find('input').trigger('change')
        },
        onChangeValues() {
          this.form = "[" + this.values.map(v => ("\"" + v + "\"") ).join(',') + "]"
          this.validForm()
        },
        onAddValue() {
          this.values.push('')
          this.onChangeValues()
        },
        onDeleteValue(index) {
          this.values.splice(index, 1)
          this.onChangeValues()
        },
        onMove() {
          this.onChangeValues()
        },
      },
    })
  }

  $('.js-list-input').each((i, element) => {
    const id = $(element).attr('id')
    setupComponent('#' + id)
  })
})
