$(() => {
  if (!$('.js-confirm-input').length) {
    return
  }

  let confirm = (input) => {
    let button = $($(input).data('button'))
    let value = $(input).val()
    let confirmText = $(input).data('text')
    if (value === confirmText) {
      button.attr('disabled', false)
    } else {
      button.attr('disabled', true)
    }
  }

  $('.js-confirm-input').on('change paste keyup', (e) => {
    confirm(e.target)
  })

  confirm($('.js-confirm-input'))
})

