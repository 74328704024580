import Vue from 'vue'

$(() => {
  if (!$('#requests_show').length) {
    return
  }

  // Slicker
  if ($('.js-slicker').length > 0) {
    let slicker = $('.js-slicker').slick({
      arrows: false,
      fade: true,
    })
    $('.js-slicker-nav-item').on('mouseenter', (e) => {
      $('.js-slicker-nav-item').removeClass('active')
      self = $(e.target) 
      self.addClass('active')
      $('.js-slicker ').slick('slickGoTo', self.data('index'))
    })
  }
})
