$(() => {
  if (!$('.js-duplicate-prevention-submit').length) {
    return
  }

  $('.js-duplicate-prevention-submit').on('click', (e) => {
    setTimeout(() => {
      $(e.target).prop('disabled', true);
    }, 100)
  });
})