$(() => {
  if (!$('.js-item-like').length) {
    if (!$('.js-request-like').length) {
      if (!$('.js-user-like').length) {
        return
      }
    }
  }

  let redirectToRegist = () => {
    window.location = '/users/regist'
  }

  $('.js-item-like').on('click',  (e) => {
    e.preventDefault();
    let self = $(e.currentTarget)
    let itemId = $(self).data('item-id')
    $.ajax({
      url: `/api/v1/items/${itemId}/likes`,
      type: 'POST',
      dataType: 'json',
      processData: false,
      contentType: false,
    }).done((data) => {
      if (data.status == 'created') {
        self.addClass('active')
      }
      if (data.status == 'deleted') {
        self.removeClass('active')
      }
    }).fail(function (msg) {
      redirectToRegist()
    })
  });

  $('.js-request-like').on('click',  (e) => {
    let self = $(e.currentTarget)
    let requestId = $(self).data('request-id')
    $.ajax({
      url: `/api/v1/requests/${requestId}/likes`,
      type: 'POST',
      dataType: 'json',
      processData: false,
      contentType: false,
    }).done((data) => {
      if (data.status == 'created') {
        self.addClass('active')
      }
      if (data.status == 'deleted') {
        self.removeClass('active')
      }
    }).fail(function (msg) {
      redirectToRegist()
    })
  });

  $('.js-user-like').on('click',  (e) => {
    let self = $(e.currentTarget)
    let userId = $(self).data('user-id')
    $.ajax({
      url: `/api/v1/users/${userId}/likes`,
      type: 'POST',
      dataType: 'json',
      processData: false,
      contentType: false,
    }).done((data) => {
      if (data.status == 'created') {
        self.addClass('active')
      }
      if (data.status == 'deleted') {
        self.removeClass('active')
      }
    }).fail((jqXHR, textStatus, errorThrown) => {
      if (jqXHR.status == 401) {
        redirectToRegist()
      }
    })
  });
})
