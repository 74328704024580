const autosize = require('autosize')

$(() => {
  if (!$('.js-auto-resize-textarea').length) {
    return
  }

  autosize($('textarea.js-auto-resize-textarea'));

})
