$(() => {
  if (!$('.js-toggle-radio').length) {
    return
  }

  let toggle = (elm) => {
    let parent = $(elm).closest('.form-group')
    let radios = parent.find('.js-toggle-radio')
    let selected = parent.find('.js-toggle-radio:checked')

    for (let i = 0; i < radios.length ; i++) {
      let target = $(radios[i]).data('target')
      if (target) {
        $(target).hide()
        $(target).addClass('js-validate-skip')
      }
      $(target).find('.is-invalid').removeClass('is-invalid')
    }

    let target = selected.data('target')
    if (target) {
      $(target).show()
      $(target).removeClass('js-validate-skip')
    }

    $('.submit').each((i, elm) => {
      $(elm).prop('disabled', false)
      $(elm).removeClass('disabled')
    })
  }

  $('.js-toggle-radio').on('change', (e) => {
    toggle(e.target)
  })

  $('.js-toggle-radio').each((i, e) => {
    toggle(e)
  })
})
