$(() => {
  if (!$('.js-category-input').length) {
    if (!$('.js-price-input').length) {
      return
    }
  }

  let existPriceForm = $('.js-price-input').length > 0

  // 大カテゴリを選択
  $('#site_category_group_id').on('change', () => {
    updateCategoryForm()
    if (existPriceForm) {
      updatePriceForm()
    }
  })

  // 小カテゴリを選択
  $('#site_category_id').on('change', () => {
    if (existPriceForm) {
      updatePriceForm()
    }
  })

  // 小カテゴリの表示状態を更新
  updateCategoryForm = () => {
    const categoryGroupId = $('#site_category_group_id').val()
    if (!categoryGroupId) {
      // 値が空
      disableCategoryForm()
    } else {
      // 値が入っている
      updateCategoryFormOption(parseInt(categoryGroupId))
    }
  }

  // 小カテゴリを非活性に
  disableCategoryForm = () => {
    select = $('#site_category_id')
    select.empty()
    select.append(`<option value=''>カテゴリを選択してください</option>`)
    select.attr('disabled', true)
  }

  // 小さいカテゴリのオプションを更新
  updateCategoryFormOption = (categoryGroupId) => {
    groups = $('#site_category_id').data('categories')
    for (let i = 0; i < groups.length; i++) {
      group = groups[i]
      if (group['group']['id'] === categoryGroupId) {
        // 対象のグループの場合の処理
        select = $('#site_category_id')
        select.attr('disabled', false)
        select.empty()
        for (let j = 0; j < group['categories'].length; j++) {
          category = group['categories'][j]
          select.append(`<option value="${category['id']}">${category['name']}</option>`)
        }
        // 第1階層のみの場合の制御（カテゴリクループ選択は非表示となり、カテゴリ選択のみの制御になる）
        const isAvailableCategoryGroup = $('#site_category_group_id').data('is-available-category-group')
        if (!isAvailableCategoryGroup) {
          const rules = select.data('rules')
          if (!rules.includes('presence')) {
            const categoryLabel = $('#site_category_group_id').data('category-label')
            select.prepend(`<option value=''>${categoryLabel}を選択してください</option>`)
          }
        }
        break;
      }
      continue;
    }
  }

  // category formを初期化
  initCategoryForm = () => {
    updateCategoryForm()
    const categoryGroupId = $('#site_category_group_id').val()
    if (!!categoryGroupId) {
      // 値が入っている
      select = $('#site_category_id')
      const categoryId = select.data('value') || select.children('option:first').val()
      select.val(categoryId)
    }
  }

  // 価格の更新
  updatePriceForm = () => {
    const categoryId = $('#site_category_id').val()
    if (!categoryId) {
      // 値が空
      disablePriceForm()
    } else {
      // 値が入っている
      updatePriceFormOption(parseInt(categoryId))
    }
  }

  // price formをdisableにする
  disablePriceForm = () => {
    select = $('.js-price-input')
    select.empty()
    select.append(`<option value=''>カテゴリを選択してください</option>`)
    select.attr('disabled', true)
    // 最低価格表記
    priceHint = $('.js-price-input_hint')
    priceHint.hide()
  }

  // price formのoptionを更新
  updatePriceFormOption = (categoryId) => {
    groups = $('#site_category_id').data('categories')
    for (let i = 0; i < groups.length; i++) {
      group = groups[i]
      for (let j = 0; j < group.categories.length; j++) {
        category = group.categories[j]
        if (category.id === categoryId) {
          // 対象カテゴリーの場合の処理
          minPrice = category.min_price
          marketPrice = category.market_price
          // セレクトを初期化
          select = $('.js-price-input')
          select.attr('disabled', false)
          select.empty()
          options = $('.js-price-input').data('default')
          options.map((option, index) => {
            if (index == 0) {
              return;
            }
            from = options[index - 1]
            to = options[index]
            fromLabel = from[0]
            fromPrice = from[1]
            toLabel = to[0]
            toPrice = to[1]
            if (toPrice >= minPrice) {
              select.append(`<option value="${fromLabel}~${toLabel}">${fromLabel}〜${toLabel}</option>`)
            }
          })
          // 最低価格表記
          priceHint = $('.js-price-input_hint')
          if (!marketPrice && !minPrice) {
            // なければ非表示
            priceHint.hide()
            return
          } else {
            priceHint.show()
          }

          if (!marketPrice) {
            priceHint.find('.js-price-input_hint_market-price').hide()
          } else {
            priceHint.find('.js-price-input_hint_market-price').show()
            priceHint.find('.js-price-input_hint_market-price_value').text(marketPrice)
          }

          if (!minPrice) {
            priceHint.find('.js-price-input_hint_min-price').hide()
          } else {
            priceHint.find('.js-price-input_hint_min-price').show()
            priceHint.find('.js-price-input_hint_min-price_value').text(minPrice + '円')
          }
          return;
        }
      }
    }
  }

  // price formを初期化
  initPriceForm = () => {
    updatePriceForm()
    const categoryId = $('#site_category_id').val()
    if (!!categoryId) {
      // 値が入っている
      select = $('.js-price-input')
      select.val(select.data('value'))
    }
  }

  initCategoryForm()
  if (existPriceForm) {
    initPriceForm()
  }
})
