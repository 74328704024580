import Quill from 'quill';
import ImageUploader from "quill-image-uploader";

$(() => {
  if (!$('.js-article-input').length) {
    return
  }

  Quill.register("modules/imageUploader", ImageUploader);

  let setupComponent = (id) => {
    let editor = id + " .js-article-input-editor"
    let value = id + " .js-article-input-value"

    let quill = new Quill(editor, {
      modules: {
        toolbar: [
          [{ header: [2, 3, false] }],
          ['bold', 'image'],
        ],
        imageUploader: {
          upload: (file) => {
            return new Promise((resolve, reject) => {
              let that = this
              let formData = new FormData()
              formData.append('image[image_file]', file)
              $.ajax({
                url: '/api/v1/images',
                type: 'POST',
                data: formData,
                dataType: 'json',
                processData: false,
                contentType: false,
              }).done((data) => {
                resolve(data.image_file.url)
              })
            });
          },
        }
      },
      placeholder: '本文を入力してください',
      theme: 'snow' 
    });
    quill.on('text-change', function() {
      console.log(quill.root.innerHTML)
      $(value).val(quill.root.innerHTML)
    });
  }

  $('.js-article-input').each((i, element) => {
    const id = $(element).attr('id')
    setupComponent('#' + id)
  })
})
