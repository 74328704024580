import Vue from 'vue'

$(() => {
  if (!$('#mypage-normal_schedules_new').length) {
    if (!$('#mypage-normal_schedules_edit').length) {
      if (!$('#mypage-normal_schedules_create').length) {
        if (!$('#mypage-normal_schedules_update').length) {
          return
        }
      }
    }
  }

  new Vue({
    el: "#normal-schdules-form",
    data: {
      availableTimes: '',
      availableTimesForSearch: '',
      times: [],
      isHoliday: false,
      copyWdayTypes: []
    },
    mounted: function() {
      let def  = $('#normal-schdules-form').data('default')
      this.times = []
      let availableTimes = def.available_times ? def.available_times.split('　') : []
      for (let availableTime of availableTimes) {
        let times = availableTime.split(' 〜 ')
        let from = times[0].split(':')
        let to = times[1].split(':')
        this.times.push({
          hour_from: parseInt(from[0]),
          minute_from: parseInt(from[1]),
          hour_to:  parseInt(to[0]),
          minute_to:  parseInt(to[1]),
        })
      } 
      if (this.times.length == 0) {
        this.isHoliday =  true
        this.onAddTime()
      }
    },
    methods: {
      dispCheckedCopyWdayType(wdayType) {
        return this.copyWdayTypes.includes(wdayType)
      },
      dispTimeError(index) {
        let time = this.times[index]
        let from =  parseInt(time.hour_from) * 100 + parseInt(time.minute_from)
        let to = parseInt(time.hour_to) * 100 + parseInt(time.minute_to)
        if (from >= to) {
          return "営業時間が不正です"
        }
        for (let i = 0; i < this.times.length; i++) {
          if (i == index) {
            continue;
          }
          let other = this.times[i]
          let otherFrom = parseInt(other.hour_from) * 100 + parseInt(other.minute_from)
          let otherTo = parseInt(other.hour_to) * 100 +parseInt(other.minute_to)

         
          if (otherFrom < to && from < otherTo) {
            return "営業時間が重複しています"
          }
        }
        return null
      },
      dispSubmitable() {
        for (let i = 0; i < this.times.length; i++) {
          if (this.dispTimeError(i)) {
            return true
          }
        }
        return false
      },
      onAddTime() {
        this.times.push({
          hour_from: 8,
          minute_from: 0,
          hour_to: 17,
          minute_to: 0,
        })
      },
      onDeleteTime(index) {
        this.times.splice(index, 1) 
      },
      onSelectCopyWdayType(wdayType) {
        if (this.copyWdayTypes.includes(wdayType)) {
          // チェック済みは削除
          this.copyWdayTypes.splice(
            this.copyWdayTypes.indexOf(wdayType), 1)
        } else {
          // 未チェックは追加
          this.copyWdayTypes.push(wdayType)
        }
      },
    },
  })
})
