// import Stripe from 'stripe';


$(() => {
  if (!$('#js-stripe-payment').length) {
    return
  }

  const form = $('#js-stripe-payment')
  const clientSecret = $('#js-stripe-payment').data('secret')
  const publicKey = $('#js-stripe-payment').data('public-key')
  const returnUrl = $('#js-stripe-payment').data('return-url')

  const stripe = Stripe(publicKey)
  const elements = stripe.elements({
      clientSecret: clientSecret,
      appearance:{
          theme: 'stripe',
      },
  });

  const paymentElement = elements.create('payment');
  paymentElement.mount('#stripe-payment-element');


// クレジットカード情報が送信された時の処理を記述する
form.submit(async (event) => {
    event.preventDefault();

    // confirmSetupを呼び出して支払い処理を完了させる
    const {error} = await stripe.confirmSetup({
        elements,
        confirmParams: {
            return_url: returnUrl,
          }
        });

      if (error) {
          const messageContainer = document.querySelector('#js-error-message');
          messageContainer.textContent = error.message;
      } else {
          // none
      }
  });
})