$(function() {
  let setSmartPhoneClass = function() {
    let w = $(window).width();
    if (w <= 575) {
      $('body').addClass('smartphone')
      $('body').removeClass('pc')
    } else {
      $('body').removeClass('smartphone')
      $('body').addClass('pc')
    }
  }
  $(window).resize(function() {
    setSmartPhoneClass()
  });
  setSmartPhoneClass()
});