$(function () {
  var textHeight = $('.js-readmore_text').height();
  var lineHeight = parseFloat($('.js-readmore_text').css('line-height'));
  var lineNum = 3;
  var textNewHeight = lineHeight * lineNum;

  if (textHeight > textNewHeight) {
    $('.js-readmore_text').css({
      'height': textNewHeight,
      'overflow': 'hidden'
    });
    $('.js-readmore_btn').show();
    $('.js-readmore_btn').click(function () {
      $(this).hide();
      $('.js-readmore_text').css({
        'height': textHeight,
        'overflow': 'visible'
      });
      return false;
    });
  };
});