import Chart from 'chart.js/auto';

export default class PVChart {
  constructor(ctx) {
    this.ctx = ctx
    let max = ctx.data('values').reduce((a,b)=>Math.max(a,b))
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
          labels: ctx.data('labels'),
          datasets: [{
              label: 'アクセス件数',
              data: ctx.data('values'),
              backgroundColor: 'rgba(52, 204, 204, 0.9)',
          },
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                  display: false
                }
            },
            y: {
                stacked: true,
                max: this.ceil(max),
                grid: {
                  borderDash: [8, 4]
                },
                ticks: {
                  maxTicksLimit: 6,
              },
            }
        }
      }
    })
  }

  ceil (num) {
    if (num < 10) {
      return 10
    }
    let length = String(num).length 
    return Math.ceil(num / (10 ** (length - 1))) * (10 ** (length - 1))
  }
}