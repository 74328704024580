$(() => {
  if (!$('[data-max]').length) {
    return
  }

  let setTextCount = (el) => {
    let textLength = $(el).val().length
    let textMax = $(el).data('max')
    if (!textMax) {
      textMax = 50
    }
    let countDown = textMax - textLength
    let target = $(el).closest('.form-group').find('.text-counter')
    targetText = '残り' + countDown + '文字'
    $(target).empty()
    $(target).text(targetText)
    if (countDown < 0) {
      return $(target).addClass('count-over')
    } else {
      return $(target).removeClass('count-over')
    }
  }

  let initializeTextCounter = function () {
    $('.text-counter').remove()
    $('[data-max]').each(function (i, el) {
      $(el).after('<small class="text-counter"></small>')
      return setTextCount(el)
    })
    return $('[data-max]').on('keydown keyup keypress change', function () {
      return setTextCount(this)
    })
  }

  return initializeTextCounter()
})
