$(() => {
  if (!$('.js-link').length) {
    return
  }

  $('.js-link').on('click', (e) => {
    if ($(e.target).closest('.js-link').hasClass('js-link-down-sm')) {
      if (!!$('body.smartphone').length) {
        // スマホ時のみ発火
        window.location = $(e.currentTarget).attr('data-href')
      }
    } else {
      window.location = $(e.currentTarget).attr('data-href')
    }
  })
})