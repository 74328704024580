import Vue from 'vue'
import axios from 'axios'

$(() => {
  if (!$('#mypage-bank_accounts_new').length) {
    if (!$('#mypage-bank_accounts_create').length) {
      if (!$('#mypage-bank_accounts_edit').length) {
        if (!$('#mypage-bank_accounts_update').length) {
          return
        }
      }
    }
  }

  new Vue({
    el: '.js-bank-form',
    data: {
      banks: [],
      searchBankText: null,
      selectBank: $('.js-bank-form').data('bank'),
      errorBank: "",

      branches: [],
      searchBranchText: null,
      selectBranch: $('.js-bank-form').data('branch'),
      errorBranch: "",

      isBranchSearched: false,
      isLoading: false,
    },
    mounted() {
      this.setDefaultBanks()
      $('.js-form-validation .submit').on('click', (e) => {
        this.validForm()
      })
    },
    methods: {
      validForm() {
        this.errors = []
        this.errorBank = null
        this.errorBranch = null
        if (!this.selectBank) {
          this.errorBank = "金融機関を選択してください"
          $('form').find('input[name="bank_account[bank_number]"]').trigger('change')
          return
        }

        if (!this.selectBranch) {
          this.errorBranch = "支店を選択してください"
          $('form').find('input[name="bank_account[branch_number]"]').trigger('change')
          return
        }
      },
      setDefaultBanks() {
        let banks = [
          ["0001", "みずほ銀行"],
          ["0005", "三菱UFJ銀行"],
          ["0009", "三井住友銀行"],
          ["0010", "りそな銀行"],
          ["0033", "PayPay銀行"],
          ["0036", "楽天銀行"],
          ["0038", "住信SBIネット銀行"],
          ["0040", "イオン銀行"],
          ["0397", "新生銀行"],
          ["9900", "ゆうちょ銀行"]
        ]
        for (let i = 0; i < banks.length; i++) {
          this.banks.push({
            number: banks[i][0],
            name: banks[i][1]
          })
        }
      },
      // 口座の一覧を取得する
      getBanks(params, callback) {
        this.isLoading = true
        axios
          .get('/api/v1/banks', {
            params: params
          })
          .then(resp => {
            this.isLoading = false
            // リスト処理
            for (let bank of resp.data.banks) {
              this.banks.push(bank)
            }
          })
      },
      // 口座の一覧を取得する
      getBranches(params, callback) {
        this.isLoading = true
        axios
          .get('/api/v1/banks/branch', {
            params: params
          })
          .then(resp => {
            this.isBranchSearched = true
            this.isLoading = false
            // リスト処理
            for (let branch of resp.data.branches) {
              this.branches.push(branch)
            }
          })
      },
      isSelectBank(bank) {
        if (!this.selectBank) {
          return false
        }
        return bank.number == this.selectBank.number
      },
      onSelectBank(bank) {
        this.selectBank = bank
        this.selectBranch = null
        this.branches = []
        this.isBranchSearched = false
        $('.close').click()
        this.validForm()
      },
      onSearchBank() {
        if (!this.searchBankText) {
          return
        }
        if (this.searchBankText.length == 0) {
          return 
        }
        this.banks = []
        this.getBanks({keyword: this.searchBankText})
      },
      isSelectBranch(branch) {
        if (!this.selectBranch) {
          return false
        }
        return branch.number == this.selectBranch.number
      },
      onSelectBranch(branch) {
        this.selectBranch = branch
        $('.close').click()
        this.validForm()
      },
      onSearchBranch() {
        if (!this.searchBranchText) {
          return
        }
        if (this.searchBranchText.length == 0) {
          return 
        }
        this.branches = []
        this.getBranches({keyword: this.searchBranchText, bank_number: this.selectBank.number})
      }
    },
  })
})
