$(() => {
  if (!$('.js-notification').length) {
    return
  }

  // お知らせ詳細開く
  $('.js-notification').on('click', function(e) {
    $.ajax({
      url: `/api/v1/notifications/${$(this).data('notification_id')}`,
      type: 'PUT',
      dataType: 'json',
      processData: false,
      contentType: false,
    }).done((data) => {
      // $('.js-notification').removeClass('js-notification') 
    })
  })
})

$(() => {
  if (!$('.js-notification-last-readed').length) {
    return
  }

  // お知らせ開く
  $('.js-notification-last-readed').on('click', () => {
    $.ajax({
      url: `/api/v1/notification_last_readeds`,
      type: 'POST',
      dataType: 'json',
      processData: false,
      contentType: false,
    }).done((data) => {
      $('.js-notification-last-readed').find('.badge-counter').remove()
      $('.js-notification-last-readed').removeClass('js-notification-last-readed') 
    })
  })
})
