import Vue from 'vue'
import axios from 'axios'


$(() => {
  if (!$('#mypage-connects_bank_account').length) {
    return
  }
  new Vue({
    el: '.js-bank-form',
    data: {
      banks: [],
      searchBankText: null,
      selectBank: $('.js-bank-form').data('bank'),
      errorBank: "",

      branches: [],
      searchBranchText: null,
      selectBranch: $('.js-bank-form').data('branch'),
      errorBank: "",
      errorBranch: "",

      isBranchSearched: false,
      isLoading: false,
    },
    mounted() {
      this.setDefaultBanks()
      $('.js-form-validation .submit').on('click', (e) => {
        this.validForm()
      })
    },
    methods: {
      validForm() {
        this.errors = []
        this.errorBank = null
        this.errorBranch = null
        if (!this.selectBank) {
          this.errorBank = "金融機関を選択してください"
          $('form').find('input[name="bank_account[bank_number]"]').trigger('change')
          return
        }

        if (!this.selectBranch) {
          this.errorBranch = "支店を選択してください"
          $('form').find('input[name="bank_account[branch_number]"]').trigger('change')
          return
        }
      },
      setDefaultBanks() {
        let banks = [
          ["0001", "みずほ銀行"],
          ["0005", "三菱UFJ銀行"],
          ["0009", "三井住友銀行"],
          ["0010", "りそな銀行"],
          ["0033", "PayPay銀行"],
          ["0036", "楽天銀行"],
          ["0038", "住信SBIネット銀行"],
          ["0040", "イオン銀行"],
          ["0397", "新生銀行"],
          ["9900", "ゆうちょ銀行"]
        ]
        for (let i = 0; i < banks.length; i++) {
          this.banks.push({
            number: banks[i][0],
            name: banks[i][1]
          })
        }
      },
      // 口座の一覧を取得する
      getBanks(params, callback) {
        this.isLoading = true
        axios
          .get('/api/v1/banks', {
            params: params
          })
          .then(resp => {
            this.isLoading = false
            // リスト処理
            for (let bank of resp.data.banks) {
              this.banks.push(bank)
            }
          })
      },
      // 口座の一覧を取得する
      getBranches(params, callback) {
        this.isLoading = true
        axios
          .get('/api/v1/banks/branch', {
            params: params
          })
          .then(resp => {
            this.isBranchSearched = true
            this.isLoading = false
            // リスト処理
            for (let branch of resp.data.branches) {
              this.branches.push(branch)
            }
          })
      },
      isSelectBank(bank) {
        if (!this.selectBank) {
          return false
        }
        return bank.number == this.selectBank.number
      },
      onSelectBank(bank) {
        this.selectBank = bank
        this.selectBranch = null
        this.branches = []
        this.isBranchSearched = false
        $('#bank-modal').modal('hide')
        this.validForm()
      },
      onSearchBank() {
        if (!this.searchBankText) {
          return
        }
        if (this.searchBankText.length == 0) {
          return 
        }
        this.banks = []
        this.getBanks({keyword: this.searchBankText})
      },
      isSelectBranch(branch) {
        if (!this.selectBranch) {
          return false
        }
        return branch.number == this.selectBranch.number
      },
      onSelectBranch(branch) {
        this.selectBranch = branch
        $('#branch-modal').modal('hide')
        this.validForm()
      },
      onSearchBranch() {
        if (!this.searchBranchText) {
          return
        }
        if (this.searchBranchText.length == 0) {
          return 
        }
        this.branches = []
        this.getBranches({keyword: this.searchBranchText, bank_number: this.selectBank.number})
      }
    },
  })
})

$(() => {
  if (!$('#mypage-connects_account').length) {
    return
  }

  const addressForm = (id) => {
    $(document).on('click', id + ' .js-connect-address-form_trigger', function (e) {
      $(id + " .js-connect-address-form_search").removeClass('d-none');
      $(id + " .js-connect-address-form_search-input").focus()
    })
    $(document).on('click', 'body', function (e) {
      if (!$.contains($(id + " .js-connect-address-form_search-wrapper")[0], e.target)) { // (2)
        $(id + " .js-connect-address-form_search").addClass('d-none');
      }
    });

    new Vue({
      el: id,
      data: {
        zipCode: '',
        lastSearchZipCodeVal: "",
        addresses: [],
        message: "郵便番号を入力して住所を選択してください",
        isSearching: false,
        form: {
          zipcode: $(id).data('zipcode'),
          state: $(id).data('state'),
          city: $(id).data('city'),
          town: $(id).data('town'),
          kanaState: $(id).data('state-kana'),
          kanaCity: $(id).data('city-kana'),
          kanaTown: $(id).data('town-kana'),
        }
      },
      mounted() {
      },
      methods: {
        resetAddresses() {
          this.message = "郵便番号を入力して住所を選択してください"
          this.addresses = []
          this.lastSearchZipCodeVal = ""
        },
        dispZipCode(zipcode) {
          if (!zipcode) {
            return
          }
          return zipcode.toString().slice(0, 3) + '-' + zipcode.toString().slice(3)
        },
        onSelectAddress(ad) {
          this.form.zipcode = ad.zipcode
          this.form.state = ad.address1
          this.form.city = ad.address2
          this.form.town = ad.address3
          this.form.kanaState = ad.kana1
          this.form.kanaCity = ad.kana2
          this.form.kanaTown = ad.kana3
          $('.js-connect-address-form_search').addClass('d-none')
        },
        isSearchable() {
          let zipCodeVal = this.zipCode.replace("-", "")
          if (!/^[0-9]{3}-[0-9]{4}$|^[0-9]{7}$/.test(zipCodeVal)) {
            return false
          }
          if (this.isSearching) {
            return false
          }
          return true
        },
        onChangeZipCode() {
          let zipCodeVal = this.zipCode.replace("-", "")
          if (this.lastSearchZipCodeVal == zipCodeVal) {
            return
          }
          if (!this.isSearchable()) {
            return
          }
          this.onAddressSearch()
        },
        onAddressSearch() {
          if (!this.isSearchable()) {
            return 
          }
          this.resetAddresses()
          let zipCodeVal = this.zipCode.replace("-", "")
          this.lastSearchZipCodeVal = zipCodeVal
          this.message = "検索中..."
          this.isSearching = true

          let url = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + zipCodeVal
          $.ajax({
            type: 'GET',
            url: url,
            dataType: 'jsonp',
          })
          .done((data) => {
            this.isSearching = false
            if (data.status != 200 || data.results == null) {
              this.message = "該当する郵便番号が見つかりませんでした"
              return
            }
            this.addresses = data.results
          })
        }
      },
    })
  }

  $('.js-connect-address-form').each((i, element) => {
    const id = $(element).attr('id')
    addressForm('#' + id)
  })
})

