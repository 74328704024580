const autosize = require('autosize')

$(() => {
  if (!$('.js-json-formatter').length) {
    return
  }

  let format = (textarea) => {
    let text = textarea.val()
    if (!text) {
      return
    }
    let textFormatted = JSON.stringify(JSON.parse(text), null, 4);
    textarea.val(textFormatted)
  }

  // focusoutで自動フォーマット
  $(document).on("focusout", ".js-json-formatter", (e) => {
    let textarea = $(e.target)
    format(textarea) 
  });

  // 初回
  $('.js-json-formatter').each((i, e) => {
    format($(e)) 
    autosize($(e));
    autosize.update($(e))
  })
})
