$(() => {
  if (!$('.js-form-help').length) {
    return
  }

  const setHelp = (el) => {
    let text = $(el).data('help')
    let dom = $(`<div class="mc-help-wrapper"><i class="fal fa-question-circle ml-2 mc-secondary"></i><div class="mc-help">${text}</div></div>`);
    $(el).append(dom)
  }

  $(document).on('mouseenter', '.mc-help-wrapper', (el) => {
    let help = $(el.target).closest('.mc-help-wrapper').find('.mc-help')
    let height = help.height()
    let width = help.width()
    help.css('top', (height + 40) * -1)
    help.css('left', (width / 2 - 3) * -1)
  })

  $('.js-form-help').each((i, el) => {
    setHelp(el)
  })
})
