$(() => {
  if (!$('.js-omise-payment').length) {
    return
  }

  Payment.formatCardNumber(document.querySelector('input[data-omise="number"]'));
  Payment.formatCardExpiry(document.querySelector('input[data-omise="exp"]'));
  Payment.formatCardCVC(document.querySelector('input[data-omise="security_code"]'));
  $('input[data-omise="exp"]').on('blur', (e) => {
    let val = $(e.target).val()
    let my = Payment.fns.cardExpiryVal(val)
    if (!my.month) {
      my.month = null
    } else {
      my.month = ('00' + my.month).slice(-2)
    }
    if (!my.year) {
      my.year = null
    } else {
      my.year = my.year % 1000
    }
    $('input[data-omise="expiration_month"]').val(my.month)
    $('input[data-omise="expiration_year"]').val(my.year)
  })

  let messageToJP = (message) => {
    if (message.indexOf("expiration") != -1) {
      return "有効期限に誤りがあります。<br>恐れ入りますが入力内容をご確認うえ再度お試しください。"
    } else if (message.indexOf("number is invalid") != -1) {
      return "カード番号に誤りがあります。<br>恐れ入りますが入力内容をご確認うえ再度お試しください。"
    } else if (message.indexOf("brand not supported") != -1) {
      return "入力されたカードのブランドはお取り扱いができません。<br>恐れ入りますが別のカード番号をご入力ください。"
    } else {
      return "入力内容に誤りがあります。<br>恐れ入りますが入力内容をご確認うえ再度お試しください。"
    }
  }

  $(".js-omise-payment").closest('form').submit(function () {
    var form = $(this);
    form.find("input[type=submit]").prop("disabled", true);
    var card = {
      "name": form.find("[data-omise=holder_name]").val(),
      "number": form.find("[data-omise=number]").val(),
      "expiration_month": form.find("[data-omise=expiration_month]").val(),
      "expiration_year": form.find("[data-omise=expiration_year]").val(),
      "security_code": form.find("[data-omise=security_code]").val()
    };
    Omise.createToken("card", card, function (statusCode, response) {
      if (response.object == "error" || !response.card.security_code_check) {
        var message_text = "SET YOUR SECURITY CODE CHECK FAILED MESSAGE";
        if(response.object == "error") {
          message_text = response.message;
        }
        $("#js-error-message").html(messageToJP(message_text));
        form.find("input[type=submit]").prop("disabled", false);
      } else {
        form.find("[name=omise_token]").val(response.id);
        form.find("[data-omise=number]").val("");
        form.find("[data-omise=security_code]").val("");
        form.get(0).submit();
      };
    });
    return false;
  });
})