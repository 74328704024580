import Chart from 'chart.js/auto';

$(() => {
  if (!$('#owner-home_index').length) {
    return
  }

  const ceil = (num) => {
    let length = String(num).length  - 1
    return Math.ceil(num / (10 ** length )) * (10 ** length)
  }

  const chart = (ctx) => {
    let maxLogined = ctx.data('logined-values').reduce((a,b)=>Math.max(a,b))
    let maxNonLongined = ctx.data('values').reduce((a,b)=>Math.max(a,b))
    let max = maxLogined + maxNonLongined
    // console.log(ceil(max))
    new Chart(ctx, {
      type: 'bar',
      data: {
          labels: ctx.data('labels'),
          datasets: [{
              label: 'ログイン状態',
              data: ctx.data('logined-values'),
              backgroundColor: 'rgba(52, 204, 204, 0.9)',
          },
          {
            label: '未ログイン状態・非会員',
            data: ctx.data('values'),
            backgroundColor: 'rgba(52, 204, 204, 0.4)',
        }]
      },
      options: {
        scales: {
            x: {
                stacked: true,
                grid: {
                  display: false
                }
            },
            y: {
                stacked: true,
                max: ceil(max),
                grid: {
                  borderDash: [8, 4]
                },
                ticks: {
                  maxTicksLimit: 6,
              },
            }
        }
    }
  });
  }

  chart($('#pv'))
  chart($('#uu'))
  chart($('#ss'))
  
  $('.js-owner-home-tab-trigger') .on('click', (e) => {
    let self =  $(e.target)
    
    // ボタンカラー変更
    $('.js-owner-home-tab-trigger').removeClass('border-primary text-primary bold')
    $('.js-owner-home-tab-trigger').addClass('border-transparent text-muted')
    $(self).removeClass('border-transparent text-muted')
    $(self).addClass('border-primary text-primary')

    // タブスイッチ
    $('.js-owner-home-tab').addClass('d-none')
    $(self.attr('target')).removeClass('d-none')
  })

})