$(() => {
  if (!$('#owner-license_petitions_show').length) {
    return
  }

  let setRejectForm = () => {
    let checked = $('input#examine_type_approved').prop("checked")

    if (checked) {
      $('#examine_type_approved-block').show()
      $('#examine_type_approved-block').removeClass('js-validate-skip')

      $('#examine_type_rejected-block').hide()
      $('#examine_type_rejected-block').addClass('js-validate-skip')
    } else {
      $('#examine_type_approved-block').hide()
      $('#examine_type_approved-block').addClass('js-validate-skip')

      $('#examine_type_rejected-block').show()
      $('#examine_type_rejected-block').removeClass('js-validate-skip')
    }
  }

  setRejectForm()

  $('input[type="radio"]').on('change', () => {
    setRejectForm()
  })
})