$(() => {
  if (!$('.js-show-detail').length) {
    return
  }

  $('.js-show-detail').on('click', (e) => {
    let self = $(e.target)
    let target = $(self.data('target'))
    let label = target.find('.mc-show-detail-trigger_label')
    if (target.hasClass('open')) {
      target.removeClass('open')
      label.text("もっと見る")
    } else {
      target.addClass('open')
      label.text("閉じる")
    }
  })
})
