import './admin/cs/site'
import './admin/debug'
import './admin/help'
import './admin/information_for_owner'
import './admin/json_generator'
import './admin/mail_magazine_for_owner'
import './admin/spec_editor'
import './mypage/bank_account'
import './mypage/chat'
import './mypage/connect'
import './mypage/item'
import './mypage/normal_schedule'
import './mypage/object'
import './mypage/order'
import './mypage/order_interview'
import './mypage/pr'
import './mypage/request'
import './mypage/specific_schedule'
import './mypage/user'
import './owner/campaign_mail'
import './owner/home'
import './owner/invoice'
import './owner/item'
import './owner/license_petition'
import './owner/manual_transfer'
import './owner/request'
import './owner/site_report'
import './owner/site_spec_home_pickup'
import './owner/special'
import './owner/transfer_summary'
import './owner/user'
import './contact'
import './demo'
import './home'
import './item'
import './order'
import './request'
