$(() => {
  if (!$('.js-sort-select').length) {
    return
  }

  let resizeWidth = (el) => {
    let text = $(el).parent().find('.js-sort-select option:selected').text()
    $(el).width(text.length * 14)
  }

  $('.js-sort-select').on('change', (e) => {
    resizeWidth(e.target)
    let url = new URL(location)
    let sort = $(e.target).val()
    url.searchParams.set("sort", sort)
    window.location.href = url.href
  })

  resizeWidth($('.js-sort-select'))
})