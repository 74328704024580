$(() => {
  if (!$('#mypage-items_new, #mypage-items_create').length) {
    return
  }

  $('.js-duplicate-modal-open').on('click', (e) => {
    setTimeout(() => {
      // 複製スクロール
      $('.js-slicker').slick({
        autoplay: false,
        dots: true,
        dotClass: 'mc-before-primary',
        centerMode: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        arrows: true,
      })
      $('.js-slicker-item').css('height', 'auto')
      $('.js-slicker-loading').hide()
    }, 500)
  })

  $('.js-duplicate-link').on('click', (e) => {
    const base = $(e.target).data('base-url')
    const itemIds = $(e.target).data('item-ids')
    const index = $('.js-slicker').slick('slickCurrentSlide')
    const url = new URL(base);
    url.searchParams.append('ref',itemIds[index]);
    window.location = url
  })
})
