import axios from 'axios'

$(() => {
  if (!$('.js-address-search').length) {
    return
  }

  let cacheSubmitButtonText = ""


  let startLoading = (button) => {
    cacheSubmitButtonText = button.text()
    button.text('')
    return button.append(
      '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    )
  }

  let stopLoading = (button) => {
    button.text(cacheSubmitButtonText)
  }

  let replaceZipcodeChar = (zipcode) => {
    zipcode = zipcode.replace(/[０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    zipcode = zipcode.replace("ー", "-").replace("−", "-")
    return zipcode
  }

  $('.js-address-search-zipcode').on('change', (e) => {
    let zipcode = $(e.target).val()
    $(e.target).val(replaceZipcodeChar(zipcode))
  })

  $('.js-address-search').on('click', (e) => {
    let button = $(e.target)
    let form = button.closest('form')
    let zipCode = form.find('.js-address-search-zipcode')
    let prefecture = form.find('.js-address-search-prefecture')
    let address = form.find('.js-address-search-address')
    let zipCodeVal = replaceZipcodeChar(zipCode.val()).replace("-", "")
    let url = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + zipCodeVal
    if (!/^[0-9]{7}$/.test(zipCodeVal)) {
      alert('郵便番号に誤りがあります')
      return
    }

    // format ok
    zipCode.val(zipCodeVal.slice(0, 3) + '-' + zipCodeVal.slice(3, 7))

    startLoading(button)
    $.ajax({
			type: 'GET',
			url: url,
			dataType: 'jsonp',
		})
		.done((data) => {
      if (data.status != 200 || data.results == null) {
        stopLoading(button)
        alert('該当する郵便番号が見つかりませんでした')
        return
      }
      let result = data.results[0]
      prefecture.val(parseInt(result.prefcode))
      address.val(result.address2 + result.address3)
      stopLoading(button)

      // form-validator用
      address.trigger('change')
		})
  })

  
})
