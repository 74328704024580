import Vue from 'vue'
import axios from 'axios'

$(() => {
  if (!$('#owner-invoices_new').length) {
    if (!$('#owner-invoices_edit').length) {
      if (!$('#owner-invoices_create').length) {
        if (!$('#owner-invoices_update').length) {
          if (!$('#owner-periodic_invoices_new').length) {
            if (!$('#owner-periodic_invoices_edit').length) {
              if (!$('#owner-periodic_invoices_create').length) {
                if (!$('#owner-periodic_invoices_update').length) {
                  return
                }
              }
            }
          }
        }
      }
    }
  }

  let defaultValue = $("#js-invoice").data('default')
  let defaultUserValue = $("#js-invoice").data('default-user')

  new Vue({
    el: "#js-invoice",
    data: {
      toUser:  {
        id: '',
        userCode: '',
        name: '',
        iconUrl: '',
        userTypeLabel: ''
      }, 
      price1: defaultValue.price_1,
      price2: defaultValue.price_2,
      price3: defaultValue.price_3,
      price4: defaultValue.price_4,
      price5: defaultValue.price_5,
      price6: defaultValue.price_6,
      price7: defaultValue.price_7,
      price8: defaultValue.price_8,
      price9: defaultValue.price_9,
      price10: defaultValue.price_10,
      tax1Type: defaultValue.tax_1_type,
      tax2Type: defaultValue.tax_2_type,
      tax3Type: defaultValue.tax_3_type,
      tax4Type: defaultValue.tax_4_type,
      tax5Type: defaultValue.tax_5_type,
      tax6Type: defaultValue.tax_6_type,
      tax7Type: defaultValue.tax_7_type,
      tax8Type: defaultValue.tax_8_type,
      tax9Type: defaultValue.tax_9_type,
      tax10Type: defaultValue.tax_10_type,
      modal: {
        isSearchUser: false,
        keyword: '',
        users: []
      }
    },
    mounted: function() {
      if (!!defaultUserValue.id) {
        this.toUser = {
          id: defaultUserValue.id,
          userCode: defaultUserValue.user_code,
          name: defaultUserValue.name,
          iconUrl: defaultUserValue.icon_url,
          userTypeLabel: defaultUserValue.user_type_label
        }
      }
    },
    methods: {
      totalPrice() {
        let sum = 0
        for (let i = 1; i <= 10; i++) {
          let price = this['price' + i]
          if (!!price) {
            sum += parseInt(price)
          } 
        }
        return sum
      },
      totalTax() {
        let sum = 0
        for (let i = 1; i <= 10; i++) {
          let taxType = this['tax' + i + 'Type']
          let tax;
          if (taxType == 'standard_10') {
            tax = 10;
          } else if (taxType == 'reduced_8' || taxType == 'standard_8') {
            tax = 8;
          } else if (taxType == 'standard_5') {
            tax = 5;
          } else {
            tax = 0;
          } 
          let price = this['price' + i]
          if (!!price) {
            sum += Math.floor(price * tax / 100)
          } 
        }
        return sum
      },
      totalPriceInTax() {
        return  this.totalPrice() + this.totalTax()
      },
      onSearchUser() {
        this.modal.users = []
        const params = {
          keyword: this.modal.keyword
        }
        this.getUsers(params, (users) => {
          this.modal.users = users
        })
      },
      onDeleteUser() {
        this.toUser = {
          id: '',
          userCode: '',
          name: '',
          iconUrl: '',
        }
      },
      onSelectUser(user) {
        this.toUser = {
          id: user.id,
          name: user.name,
          userCode: user.user_code,
          iconUrl: user.icon_url,
          userTypeLabel: user.user_type_label,
        }
        $('#to-user-modal').modal('hide')
      },
       // Userの一覧を取得する
       getUsers(params, callback) {
        if (this.modal.isSearchUser) {
          return
        }
        this.modal.isSearchUser = true
        axios
          .get('/api/v1/users', {
            params: params
          })
          .then(resp => {
            this.modal.isSearchUser = false
            callback(resp.data.users)
          })
      },
    },
  })
})
