import "particles.js";

$(() => {
  if (!$('.mc-particles').length) {
    return
  }


  // 
  // <div id="js-particles" class="mc-particles"></div> で発火
  // 
  particlesJS('js-particles',
    {
      "particles": {
        "number": {
          "value": 40,
        },
        "opacity": {
          "value": 0.3,
        },
        "size": {
          "value": 1,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 100,
            "size_min": 0.1,
            "sync": false
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 150,
          "color": "#ffffff",
          "opacity": 0.4,
          "width": 1
        },
        "move": {
          "enable": true,
          "speed": 2
        }
      },
      "interactivity": {
        "events": {
          "onhover": {
            "enable": false,
          },
          "onclick": {
            "enable": false,
          },
          "resize": true
        },
      },
      "retina_detect": true
    }
  );
})