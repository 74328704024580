$(() => {
  if (!$('.js-radio-pannel').length) {
    return
  }

  $('.js-radio-pannel').on('click', (e) => {
    let pannel = null
    if ($(e.target).closest(".js-radio-pannel").length) {
      pannel = $(e.target).closest('.js-radio-pannel')
    } else {
      pannel = $(e.target)
    }
    let active = pannel.hasClass('active')
    if (!active) {
      let wrapper = $(pannel).closest('.js-radio-pannel-wrapper')
      // pannel
      wrapper.find('.js-radio-pannel').removeClass('active')
      $(pannel).addClass('active')
      // content
      wrapper.find('.js-radio-pannel-content').removeClass('show')
      $(pannel.data('target')).addClass('show')
    }
  })
})
