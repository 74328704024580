$(() => {
  if (!$('.js-json-sample').length) {
    return
  }

  $('.js-json-sample').each((_, elm) => {
    let dom = `<div class='js-json-sample-fire cursor-pointer f12 text-info'>サンプル</div>`
    $(elm).after(dom)
  })

  $(document).on("click", ".js-json-sample-fire", (e) => {
    let triggerElm = $(e.target).parent().find('textarea')
    let sample = $(triggerElm).data('sample')
    let html = JSON.stringify(sample, null, 4);
    navigator.clipboard.writeText(html)
  });
})
