$(() => {
  if (!$('#mypage-requests_add').length) {
    if (!$('#mypage-requests_new').length) {
      if (!$('#mypage-requests_create').length) {
        return
      }
    }
  }

  if (!!$('#mypage-requests_add').length) {
    $('#js-radio-pannel-content-1 input').on('change', () => {
      if ($('#js-radio-pannel-content-1 input:checked').length) {
        $('#js-radio-pannel-content-1 button').prop('disabled', false)
      } else {
        $('#js-radio-pannel-content-1 button').prop('disabled', true)
      }
    })

    $('#js-radio-pannel-content-2 input').on('change', () => {
      if ($('#js-radio-pannel-content-2 input:checked').length) {
        $('#js-radio-pannel-content-2 button').prop('disabled', false)
      } else {
        $('#js-radio-pannel-content-2 button').prop('disabled', true)
      }
    })
  }

  if (!!$('#mypage-requests_new').length || !!$('#mypage-requests_create').length) {
    $('.js-duplicate-modal-open').on('click', (e) => {
      setTimeout(() => {
        // 複製スクロール
        $('.js-slicker').slick({
          autoplay: false,
          dots: true,
          dotClass: 'mc-before-primary',
          centerMode: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          arrows: true,
        })
      $('.js-slicker-item').css('height', 'auto')
      $('.js-slicker-loading').hide()
      }, 500)
    })


    $('.js-duplicate-link').on('click', (e) => {
      let base = $(e.target).data('base-url')
      let requestIds = $(e.target).data('request-ids')
      let index = $('.js-slicker').slick('slickCurrentSlide')
      var url = new URL(base);
      url.searchParams.append('ref',requestIds[index]);
      window.location = url
    })
  }

})
