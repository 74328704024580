import Vue from 'vue'

$(() => {
  if (!$('.js-profile-categories-input').length) {
    return
  }

  const profileCategoriesInput = (id) => {
    new Vue({
      el: id,
      data: {
        selectedSiteCategoryIds: $(id).data('selected-ids'),
        datas: $(id).data('datas'),
        isAvailableCategoryGroup: $(id).data('is-available-category-group'),
        rules: $(id).data('rules'),
        errors: [], // Validエラーメッセージ
      },
      mounted() {
        $('.js-form-validation .submit').on('click', (e) => {
          this.validForm()
        })
      },
      methods: {
        // CategoryGroupのForm ID
        dispSiteCategoryGroupFormId(group) {
          return 'profile_profile-categories-input_site_category_group_' + group.id
        },
        // CategoryGroupがチェック済のCategoryを持っているか
        haSselectedSiteCategories(data) {
          for (let i = 0; i < data.categories.length; i++) {
            let category = data.categories[i]
            for (let j = 0; j < $(id).data('selected-ids').length; j++) {
              let selectedId = $(id).data('selected-ids')[j]
              if (category.id == selectedId) {
                return true
              }
            }
          }
          return false
        },
        // そのカテゴリが選択可能か
        isClickableSiteCategory(category) {
          if (this.rules.includes("max")) {
            return this.selectedSiteCategoryIds.includes(category.id) || this.selectedSiteCategoryIds.length < 10
          } else {
            return true
          }
        },
        validForm() {
          this.errors = []
          if (this.rules.includes("presence") && this.selectedSiteCategoryIds.length == 0) {
            this.errors.push("少なくとも1件以上選択してください")
          }

          $(id).find('input').trigger('change')
        },
        onSelectSiteCategory(category) {
          if (!this.isClickableSiteCategory(category)) {
            return
          }

          if (this.selectedSiteCategoryIds.includes(category.id)) {
            // チェック済みは削除
            this.selectedSiteCategoryIds.splice(
              this.selectedSiteCategoryIds.indexOf(category.id), 1)
          } else {
            // 未チェックは追加
            this.selectedSiteCategoryIds.push(category.id)
            this.selectedSiteCategoryIds.sort((a,b) => {
                if(a.sort > b.sort) return -1;
                if(a.sort < b.sort) return 1;
                return 0;
            });
          }
          this.validForm()
        },
      },
    })
  }

  $('.js-profile-categories-input').each((i, element) => {
    const id = $(element).attr('id')
    profileCategoriesInput('#' + id)
  })
})
