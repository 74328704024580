$(() => {
  if (!$('.js-db-backlink').length) {
    return
  }

  href = $('.js-db-backlink').data('href')
  $('.breadcrumbs .current').after(
    `<a class="db-backlink" href="${href}">前の画面へ戻る</a>`
  )
})
