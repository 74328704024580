$(() => {
  const targetSelector = '.js-japanese-era'
  if (!$(targetSelector).length) {
    return
  }

  // 和暦の名称および日付範囲の管理
  const jpnEras = [
    { shortName: 'S', name: '昭和', from: new Date(1926, 11, 25), to: new Date(1989, 0, 7) },
    { shortName: 'H', name: '平成', from: new Date(1989, 0, 8), to: new Date(2019, 3, 30) },
    { shortName: 'R', name: '令和', from: new Date(2019, 4, 1), to: new Date(new Date().getFullYear() + 30, 11, 31) }
  ]

  /**
   * セットアップ
   *
   * @param {string} id ID属性値
   * @param {Object} _ 要素(未使用)
   */
  const setupComponent = (id, _) => {
    const parentElement = $(`#${id}`)
    const required = parentElement.find('.js-japanese-era-label').hasClass('required')
    const adDateHidden = parentElement.find('.js-japanese-era-ad-date-hidden')
    const adDateWasHidden = parentElement.find('.js-japanese-era-ad-date-was-hidden')
    const eraSelect = parentElement.find('.js-japanese-era-select')
    const eraYearSelect = parentElement.find('.js-japanese-era-year-select')
    const monthSelect = parentElement.find('.js-japanese-era-month-select')
    const daySelect = parentElement.find('.js-japanese-era-day-select')
    const clearBtn = parentElement.find('.js-japanese-era-clear-btn')

    let defaultEra = ''
    let defaultEraYear = ''
    let defaultMonth = ''
    let defaultDay = ''

    /**
     * 各種セレクトボックス初期化
     */
    const initializeSelectBoxes = () => {
      // 元号セレクトボックス生成
      createJpnEraOptions()

      // 年月日のセレクトボックス
      const { era, year, month, day } = getJpnEraPropetries(adDateHidden.val())
      eraSelect.val(era)
      eraSelect.trigger('change')
      eraYearSelect.val(year)
      eraYearSelect.trigger('change')
      monthSelect.val(month)
      monthSelect.trigger('change')
      daySelect.val(day)
      daySelect.trigger('change')
    }

    /**
     * 変更前の日付を保持する
     */
    const storeJpnEraDate = () => {
      if (!required) {
        // 任意項目のときはすべて空欄に戻す
        return
      }

      // 必須項目のときはDB値または当日日付
      const { era, year, month, day } = getJpnEraPropetries(adDateWasHidden.val())
      defaultEra = era || eraSelect.val()
      defaultEraYear = year || eraYearSelect.val()
      defaultMonth = month || monthSelect.val()
      defaultDay = day || daySelect.val()
    }

    /**
     * 保持している日付を戻す
     */
    const restoreJpnEraDate = () => {
      eraSelect.val(defaultEra)
      eraSelect.trigger('change')
      eraYearSelect.val(defaultEraYear)
      eraYearSelect.trigger('change')
      monthSelect.val(defaultMonth)
      monthSelect.trigger('change')
      daySelect.val(defaultDay)
      monthSelect.trigger('change')
    }

    /**
     * 和暦を西暦に変換して返却する
     *
     * @param {string} era 元号
     * @param {number} eraYear 年
     * @param {number} month 月
     * @param {number} day 日
     * @return {Date} 西暦
     */
    const convertAdDateByJpnEraDate = (era, eraYear, month = 1, day = 1) => {
      const jpnEra = jpnEras.find((e) => e.shortName === era)
      const adYear = jpnEra.from.getFullYear() + eraYear - 1
      return new Date(adYear, month - 1, day)
    }

    /**
     * 和暦の要素を返す
     *
     * @param {string} strAdDate 西暦の日付文字列
     * @return {Object} 元号・年・月・日
     */
    const getJpnEraPropetries = (strAdDate) => {
      let adDate = strAdDate.length ? new Date(strAdDate) : null
      if (required && !adDate) {
        adDate = new Date(new Date().toDateString()) // 年月日のみ
      }
      if (!adDate) {
        return { era: null, year: null, month: null, day: null }
      }

      // 和暦のフォーマット
      const jpnEraFormatter = new Intl.DateTimeFormat(
        'ja-JP-u-ca-japanese',
        { era: 'narrow', year: 'numeric', month: 'numeric', day: 'numeric' }
      )
      const jpnEraDate = jpnEraFormatter.format(adDate)
      const jpnEra = jpnEraDate.slice(0, 1)
      const jpnEraYear = jpnEraDate.replace(jpnEra, '').split('/')[0]
      return {
        era: jpnEra,
        year: jpnEraYear,
        month: adDate.getMonth() + 1,
        day: adDate.getDate()
      }
    }

    /**
     * 元号セレクトボックスの要素を生成する
     */
    const createJpnEraOptions = () => {
      if (!required) {
        const option = document.createElement('option')
        option.value = ''
        option.textContent = ''
        eraSelect.append(option)
      }
      jpnEras.forEach((jpnEra) => {
        const option = document.createElement('option')
        option.value = jpnEra.shortName
        option.textContent = jpnEra.name
        eraSelect.append(option)
      })
    }

    /**
     * 年セレクトボックスの要素を更新する
     */
    const updateYearOptions = () => {
      const era = eraSelect.val();
      if (!era.length) {
        return
      }

      const currentYear = eraYearSelect.val();
      eraYearSelect.empty();
      const maxYear = getMaxJpnEraYear(era);
      if (!required) {
        const option = document.createElement('option')
        option.value = ''
        option.textContent = ''
        eraYearSelect.append(option)
      }
      for (let year = 1; year <= maxYear; year++) {
        const option = document.createElement('option')
        option.value = year
        option.textContent = year
        option.selected = (currentYear === year.toString())
        eraYearSelect.append(option)
      }
    }

    /**
     * 月セレクトボックスの要素を更新する
     */
    const updateMonthOptions = () => {
      const era = eraSelect.val();
      const eraYear = eraYearSelect.val();
      if (!era.length || !eraYear.length) {
        return
      }

      const currentMonth = monthSelect.val();
      monthSelect.empty();
      const { minMonth, maxMonth } = getMinMaxJpnEraMonth(era, eraYear)
      if (!required) {
        const option = document.createElement('option')
        option.value = ''
        option.textContent = ''
        monthSelect.append(option)
      }
      for (let month = minMonth; month <= maxMonth; month++) {
        const option = document.createElement('option')
        option.value = month
        option.textContent = month
        option.selected = (currentMonth === month.toString())
        monthSelect.append(option)
      }
    }

    /**
     * 日セレクトボックスの要素を更新する
     */
    const updateDayOptions = () => {
      const era = eraSelect.val()
      const eraYear = eraYearSelect.val()
      const eraMonth = monthSelect.val()
      if (!era.length || !eraYear.length || !eraMonth.length) {
        return
      }

      const currentDay = daySelect.val();
      daySelect.empty();
      const { minDay, maxDay } = getMinMaxJpnEraDay(era, eraYear, eraMonth)
      if (!required) {
        const option = document.createElement('option')
        option.value = ''
        option.textContent = ''
        daySelect.prepend(option)
      }
      for (let day = minDay; day <= maxDay; day++) {
        const option = document.createElement('option')
        option.value = day
        option.textContent = day
        option.selected = (currentDay === day.toString())
        daySelect.append(option)
      }
    }

    /**
     * 各種セレクトボックスの値から隠しフィールドの値(西暦)を更新する
     */
    const updateAdDateHidden = () => {
      const era = eraSelect.val()
      const eraYear = eraYearSelect.val()
      const eraMonth = monthSelect.val()
      const eraDay = daySelect.val();
      if (!era.length || !eraYear.length || !eraMonth.length || !eraDay.length) {
        adDateHidden.val('')
        return
      }

      const adDate = convertAdDateByJpnEraDate(era, parseInt(eraYear), parseInt(eraMonth), parseInt(eraDay))
      const strAdDate = adDate.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit',day: '2-digit' }).replaceAll('/', '-')
      adDateHidden.val(strAdDate)
    }

    /**
     * 引数の元号の最大年を返却する
     * ex) H(平成) -> 31
     *
     * @param {string} era 元号
     * @return {number} 最大年
     */
    const getMaxJpnEraYear = (era) => {
      const jpnEra = jpnEras.find((jpnEra) => jpnEra.shortName === era)
      return jpnEra.to.getFullYear() - jpnEra.from.getFullYear() + 1
    }

    /**
     * 引数の元号・年の最小月および最大月を返却する
     * ex) H31(平成31年) -> 最小月: 1, 最大月: 4
     *
     * @param {string} era
     * @param {number} eraYear
     * @return {Object} 最小月(minMonth), 最大月(maxMonth)
     */
    const getMinMaxJpnEraMonth = (era, eraYear) => {
      const jpnEra = jpnEras.find((je) => je.shortName === era)
      const adDate = convertAdDateByJpnEraDate(era, parseInt(eraYear))
      let minMonth = 1
      let maxMonth = 12
      // 元年のとき
      if (adDate.getFullYear() === jpnEra.from.getFullYear()) {
        minMonth = jpnEra.from.getMonth() + 1
      }
      // 元号の最終年
      if (adDate.getFullYear() === jpnEra.to.getFullYear()) {
        maxMonth = jpnEra.to.getMonth() + 1
      }

      return { minMonth: minMonth, maxMonth: maxMonth }
    }

    /**
     * 引数の元号・年・月の最小日および最大日を返却する
     * ex) S64.01 (昭和64年1月) -> 最小日: 1, 最大日: 7
     *
     * @param {string} era 元号
     * @param {number} eraYear 年
     * @param {number} eraMonth 月
     * @return {Object} 最小日(minDay), 最大日(maxDay)
     */
    const getMinMaxJpnEraDay = (era, eraYear, eraMonth) => {
      const jpnEra = jpnEras.find((je) => je.shortName === era)
      const adDate = convertAdDateByJpnEraDate(era, parseInt(eraYear), parseInt(eraMonth))
      let minDay = 1
      let maxDay = 31
      if (adDate.getFullYear() === jpnEra.from.getFullYear() && adDate.getMonth() === jpnEra.from.getMonth()) {
        // 元号の開始月のとき
        minDay = jpnEra.from.getDate()
      } else if (adDate.getFullYear() === jpnEra.to.getFullYear() && adDate.getMonth() === jpnEra.to.getMonth()) {
        // 元号の終了月のとき
        maxDay = jpnEra.to.getDate()
      } else {
        // 月の日数 -> 次月の0日
        maxDay = new Date(adDate.getFullYear(), adDate.getMonth() + 1, 0).getDate()
      }

      return { minDay: minDay, maxDay: maxDay }
    }

    eraSelect.on('change', () => {
      updateYearOptions()
      updateMonthOptions()
      updateDayOptions()
      updateAdDateHidden()
    })

    eraYearSelect.on('change', () => {
      updateMonthOptions()
      updateDayOptions()
      updateAdDateHidden()
    })

    monthSelect.on('change', () => {
      updateDayOptions()
      updateAdDateHidden()
    })

    daySelect.on('change', () => {
      updateAdDateHidden()
    })


    clearBtn.on('click', () => {
      restoreJpnEraDate()
    })

    // 各種セレクトボックス初期化
    initializeSelectBoxes()

    // 初期値設定
    storeJpnEraDate()
  }

  $(targetSelector).each((_, element) => {
    const id = $(element).attr('id')
    setupComponent(id, element)
  })
})
