import Quill from 'quill';

$(() => {
  if (!$('#admin-information_for_owners_new').length) {
    if (!$('#admin-information_for_owners_edit').length) {
      if (!$('#admin-information_for_owners_create').length) {
        if (!$('#admin-information_for_owners_update').length) {
          return
        }
      }
    }
  }

  let quill = new Quill('#js-editor', {
    modules: {
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'image'],
      ]
    },
    placeholder: '本文を入力してください',
    theme: 'snow' 
  });
  quill.on('text-change', function() {
    $("#information_for_owner_body").val(quill.root.innerHTML)
  });
});