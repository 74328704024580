import Vue from 'vue'

$(() => {
  if (!$('.js-secure-document-file-list-input').length) {
    return
  }

  const setupComponent = (id) => {
    new Vue({
      el: id,
      data: {
        rules: $(id).data('rules'), // Validルール
        documentFiles: $(id).data('secure-document-files'),
        formValue: '',
        errors: [],
      },
      mounted() {
        this.formValue = JSON.stringify(this.documentFiles)
        $('.js-form-validation .submit').on('click', (e) => {
          this.validForm()
        })
      },
      methods: {
        validForm() {
          if (!this.rules || this.rules.length == 0) {
            // 未定義はリターン
            return
          }
          this.errors = []
          if (this.documentFiles.length == 0) {
            this.errors.push("少なくとも1件以上入力してください")
          }
  
          $(id).find('input').trigger('change')
        },
        selectedFile(e) {
          e.preventDefault()
          let that = this
          let formData = new FormData()
          formData.append('secure_document[document_file]', e.target.files[0])
          $.ajax({
            url: '/api/v1/secure_documents',
            type: 'POST',
            data: formData,
            dataType: 'json',
            processData: false,
            contentType: false,
          }).done((data) => {
            that.appendUrl(data)
          })
        },
        appendUrl(documentFile) {
          this.documentFiles.push(documentFile)
          this.formValue = JSON.stringify(this.documentFiles)
          this.validForm()
        },
        deleteUrl(index) {
          this.documentFiles.splice(index, 1)
          this.formValue = JSON.stringify(this.documentFiles)
          this.validForm()
        },
        // byteを表示用の変換
        formatBytes(bytes, decimals = 1)  {
          if (bytes === 0) return '0 Bytes';

          const k = 1024;
          const dm = decimals < 0 ? 0 : decimals;
          const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

          const i = Math.floor(Math.log(bytes) / Math.log(k));
          return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
      },
    })
  }

  $('.js-secure-document-file-list-input').each((i, element) => {
    const id = $(element).attr('id')
    setupComponent('#' + id)
  })
})
