'use strict'

export default class BlockConnect {
  constructor(canvas, e1, e2, options = {}) {
    this.canvas = canvas
    this.e1 = document.getElementById(e1)
    this.e2 = document.getElementById(e2)
    if (!this.e1 || !this.e2) {
      return
    }
    this.lineColor = options.color || '#a4a4a4' // 線の色
    this.lineWidth = options.lineWidth || 1  // 線の太さ
    this.radius = 0 // カーブの大きさ
    this.offsetY1 = options.offsetY1 || 0 // e1のスタート地点のY座標オフセット
    this.offsetY2 = options.offsetY2 || 0 // e2のスタート地点のY座標オフセット
    this.halfPointType = options.halfPointType || 'center' // 折り曲がり地点。centerならe1とe2の間、rightならe2より
    this.halfPointMargin = options.halfPointMargin || 13 // 折り曲がり地点からe1 or e2までのmargin

    this.draw()
  }

  draw() {
    const ctx = this.canvas.getContext('2d')
    ctx.strokeStyle = this.lineColor
    ctx.lineWidth = this.lineWidth
    ctx.lineCap = "round"
  
    const er1 = this.getElementReact(this.e1)
    const er2 = this.getElementReact(this.e2)

    // e1のスタート地点
    const er1YStart = er1.top + (er1.bottom - er1.top) / 2 + this.offsetY1 
    // e2のゴール地点
    const er2YGoal = er2.top + (er2.bottom - er2.top) / 2 + this.offsetY2
    // 折り曲がり地点
    let xHalf = null;
    if (this.halfPointType === 'right') {
      xHalf = er2.left - this.halfPointMargin
    } else if (this.halfPointType === 'left') {
      xHalf = er2.left + this.halfPointMargin
    } else {
      xHalf = er1.right + (er2.left - er1.right) / 2
    }

    ctx.beginPath();
    ctx.moveTo(er1.right, er1YStart);
    ctx.lineTo(xHalf - this.radius, er1YStart);
    if (er1YStart < er2YGoal) {
      // e2が右上にある場合
      ctx.quadraticCurveTo(xHalf, er1YStart, xHalf, er1YStart + this.radius)
      ctx.lineTo(xHalf, er2YGoal - this.radius);
      ctx.quadraticCurveTo(xHalf, er2YGoal, xHalf + this.radius, er2YGoal);
      ctx.lineTo(er2.left, er2YGoal);
    } else if (er1YStart > er2YGoal) {
      // e2が左下にある場合
      ctx.quadraticCurveTo(xHalf, er1YStart, xHalf, er1YStart - this.radius)
      ctx.lineTo(xHalf, er2YGoal + this.radius);
      ctx.quadraticCurveTo(xHalf, er2YGoal, xHalf + this.radius, er2YGoal);
      ctx.lineTo(er2.left, er2YGoal)
    } else {
      // 同じ高さの場合
      ctx.lineTo(er2.left, er1YStart)
    }
    ctx.stroke();
  }

  getElementReact(e) {
    const er = e.getBoundingClientRect()
    const cr = this.canvas.getBoundingClientRect()
    return {
      height: er.height,
      width:  er.width,
      left:   er.left - cr.left,
      right:  er.right - cr.left,
      top:    er.top - cr.top,
      bottom: er.bottom - cr.top,
      x:      er.left - cr.left,
      y:      er.top - cr.top,
    }
  }
}