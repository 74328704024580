import Vue from 'vue'
import VueTextareaAutosize from 'vue-textarea-autosize'
import draggable from 'vuedraggable'
import axios from 'axios'

$(() => {
  if (!$('#owner-specials_new').length) {
    if (!$('#owner-specials_edit').length) {
      if (!$('#owner-specials_create').length) {
        if (!$('#owner-specials_update').length) {
          return
        }
      }
    }
  }

  Vue.use(VueTextareaAutosize);

  const id = '#js-special-form'
  new Vue({
    el: id,
    components: {
      'draggable': draggable,
    },
    data: {
      special: $(id).data('special'),
      contents: [],
      editingContentsIndex: null, // 編集中のcontent
      addImage: null,
      isModalItemSeraching: true,
      isModalRequestSeraching: true,
      isFormChanged: false,
      isSaving: false,
      modalForm: {
        html: "", // modalのhtmlフォーム
        items: [], // item
        selectedItems: [], // item
        requests: [], // request
        selectedRequests: [], // requests
      },
      modalButtonForm: {
        title: "",
        actionType: "link",
        linkUrl: "",
        documentFileName: "",
        documentFileSize: null,
        documentFileUrl: "",
      },
      modalImageForm: {
        alt: '',
        linkUrl: '',
      },
    },
    created() {
      this.setupSpecialContents()
    },
    mounted() {
      $(window).on('beforeunload', (e) => {
        // 保存時はスキップ
        if (this.isSaving) {
          return
        }

        // metaタグはVue管理外
        // 他のモーダルの仕様に合わせて、表示した時点で変更済みとしている
        if ($('#special_is_meta_edited').val() === '1') {
          this.onChangeForm()
        }

        if (this.isFormChanged) {
          e.preventDefault()
          e.returnValue = ''
        }
      })
    },
    watch: {
      special: {
        handler(_newVal, _oldVal) {
          this.onChangeForm()
        },
        deep: true
      },
      contents: {
        handler(_newVal, _oldVal) {
          this.onChangeForm()
        },
        deep: true
      },
      modalForm: {
        handler(_newVal, _oldVal) {
          this.onChangeForm()
        },
        deep: true
      },
      modalButtonForm: {
        handler(_newVal, _oldVal) {
          this.onChangeForm()
        },
        deep: true
      },
      modalImageForm: {
        handler(_newVal, _oldVal) {
          this.onChangeForm()
        },
        deep: true
      }
    },
    methods: {
      onChangeBackgroundImage(e) {
        let files = e.target.files || e.dataTransfer.files;
        this.previewImage(files[0]);
        $(id).find('#special_remove_background_image_file').val(null)
      },
      onDeleteBackgroundImage() {
        this.special.background_image_file = null
        $(id).find('#special_remove_background_image_file').val('on')
      },
      onAddHeading() {
        this.contents.push({
          body: '',
          type: "heading",
          sort: 0,
        })
      },
      onAddButton() {
        this.contents.push({
          title: '会員登録はこちら',
          action_type: 'link',
          link_url: "/users/regist",
          type: "button",
          sort: 0,
        })
      },
      onAddHtml() {
        this.contents.push({
          body: '',
          type: "html",
          sort: 0,
        })
      },
      onAddSentence() {
        this.contents.push({
          body: '',
          type: "sentence",
          sort: 0,
        })
      },
      onAddImage(e) {
        const images = e.target.files || e.dataTransfer.files
        const maxWidth = e.target.dataset.maxWidth
        const maxHeight = e.target.dataset.maxHeight
        this.getBase64(images[0], maxWidth, maxHeight)
          .then((image) => {
            this.contents.push({
              image_file: image,
              image_source: image,
              type: "image",
              sort: 0,
            })
          })
      },
      onAddItemList(e) {
        this.contents.push({
          title: '',
          description: '',
          item_ids: [],
          type: "item_list",
          sort: 0,
        })
      },
      onAddRequestList(e) {
        this.contents.push({
          title: '',
          description: '',
          request_ids: [],
          type: "request_list",
          sort: 0,
        })
      },
      onDeleteContents(index) {
        this.contents = this.contents.filter((content, i) => {
          if (index == i) {
            content._destroy = 1
          }
          return content
        })
      },
      // 下書き保存
      onSumitDraft() {
        this.isSaving = true
        this.contents.map((content, index) => {
          content.sort = index
        })
      },
      onSumit(e) {
        this.onSumitDraft()
      },
      onChangeForm() {
        if (document.readyState === 'complete') {
          this.isFormChanged = true
        }
      },
      // ************************************************
      //
      //  画像
      //
      // ************************************************
      // モーダル表示
      onShowImageModal(index, content) {
        this.editingContentsIndex = index
        this.modalImageForm.alt = content.alt
        this.modalImageForm.linkUrl = content.link_url
        $('#special-image-modal').modal()
      },
      // モーダルサブミット
      onSubmitImageModal() {
        $('#special-image-modal').modal('hide')
        const index = this.editingContentsIndex
        const content = this.contents[index]
        content.alt = this.modalImageForm.alt
        content.link_url = this.modalImageForm.linkUrl
        this.$set(this.contents, index, content)
      },

      // ************************************************
      //
      //  button
      //
      // ************************************************
      // モーダル表示
      onShowButtonModal(index, content) {
        console.log(content)
        this.editingContentsIndex = index
        this.modalButtonForm.title = content.title
        this.modalButtonForm.actionType = content.action_type
        this.modalButtonForm.linkUrl = content.link_url
        this.modalButtonForm.documentFileName = content.document_file_name
        this.modalButtonForm.documentFileSize = content.document_file_size
        this.modalButtonForm.documentFileUrl = content.document_file_url
        $('#special-button-modal').modal()
      },
      // モーダルサブミット
      onSubmitButtonModal() {
        $('#special-button-modal').modal('hide')
        let index = this.editingContentsIndex
        let content = this.contents[index]
        content.title = this.modalButtonForm.title
        content.action_type = this.modalButtonForm.actionType
        content.link_url = this.modalButtonForm.linkUrl
        content.document_file_name = this.modalButtonForm.documentFileName
        content.document_file_size = this.modalButtonForm.documentFileSize
        content.document_file_url = this.modalButtonForm.documentFileUrl
        this.$set(this.contents, index, content)
      },
      onSelectedFileButton(e) {
        e.preventDefault()
        let that = this
        let formData = new FormData()
        formData.append('document[document_file]', e.target.files[0])
        $.ajax({
          url: '/api/v1/documents',
          type: 'POST',
          data: formData,
          dataType: 'json',
          processData: false,
          contentType: false,
        }).done((data) => {
          that.modalButtonForm.documentFileName = data.file_name
          that.modalButtonForm.documentFileSize = data.file_size
          that.modalButtonForm.documentFileUrl = data.url
        })
      },
      deleteUrl(index) {
        this.documentFiles.splice(index, 1)
        this.formValue = JSON.stringify(this.documentFiles)
      },

      // ************************************************
      //
      //  html
      //
      // ************************************************
      // モーダル表示
      onShowHtmlModal(index, content) {
        this.editingContentsIndex = index
        this.modalForm.html = Vue.util.extend(content.body)
        $('#special-html-modal').modal()
      },
      // モーダルサブミット
      onSubmitHtmlModal() {
        $('#special-html-modal').modal('hide')
        let index = this.editingContentsIndex
        let content = this.contents[index]
        content.body = this.modalForm.html
        this.$set(this.contents, index, content)
      },
      // ************************************************
      //
      //  item
      //
      // ************************************************
      // モーダル表示
      onShowItemListModal(index, content) {
        this.editingContentsIndex = index
        this.modalForm.items = []
        this.modalForm.selectedItems = Vue.util.extend([], content.items)
        $('#special-item-list-modal').modal()
        this.getItems({}, (items) => {
          this.modalForm.items = items
        })
      },
      // モーダルサブミット
      onSubmitItemListModal() {
        $('#special-item-list-modal').modal('hide')
        let index = this.editingContentsIndex
        let content = this.contents[index]
        content.item_ids = '[' + this.modalForm.selectedItems.map(item => item.id) + ']'
        content.items = this.modalForm.selectedItems
        this.$set(this.contents, index, content)
        this.getItemViews(index, content.items.map(item => item.id))
      },
      // モーダルitem選択
      onSelectModalItem(item) {
        this.modalForm.selectedItems.push(item)
      },
      onUnSelectModalItem(item) {
        let index = this.modalForm.selectedItems.findIndex(it => it.id === item.id)
        this.modalForm.selectedItems.splice(index, 1)
      },
      // モーダルの検索をenter時
      onEnterItemListModal(e) {
        e.preventDefault()
        this.modalForm.items = []
        const params = {
          keyword: $(e.target).val()
        }
        this.getItems(params, (items) => {
          this.modalForm.items = items
        })
      },
      // モーダル内Itemの並び替え完了
      onSortedSelectModalItem() {
        let index = this.editingContentsIndex
        let content = this.contents[index]
        content.items = this.modalForm.selectedItems
        this.$set(this.contents, index, content)
        this.getItemViews(index, content.items.map(item => item.id))
      },
      // ************************************************
      //
      //  request
      //
      // ************************************************
      // モーダル表示
      onShowRequestListModal(index, content) {
        this.editingContentsIndex = index
        this.modalForm.requests = []
        this.modalForm.selectedRequests = Vue.util.extend([], content.requests)
        $("#special-request-list-modal").modal()
        this.getRequests({}, (requests) => {
          this.modalForm.requests = requests
        })
      },
      // モーダルサブミット
      onSubmitRequestListModal() {
        $("#special-request-list-modal").modal('hide')
        let index = this.editingContentsIndex
        let content = this.contents[index]
        content.request_ids = '[' + this.modalForm.selectedRequests.map(request => request.id) + ']'
        content.requests = this.modalForm.selectedRequests
        this.$set(this.contents, index, content)
        this.getRequestViews(index, content.requests.map(request => request.id))
      },
      // モーダルrequest選択
      onSelectModalRequest(request) {
        this.modalForm.selectedRequests.push(request)
      },
      onUnSelectModalRequest(request) {
        let index = this.modalForm.selectedRequests.findIndex(it => it.id === request.id)
        this.modalForm.selectedRequests.splice(index, 1)
      },
      // モーダルの検索をenter時
      onEnterRequestListModal(e) {
        e.preventDefault()
        this.modalForm.requests = []
        const params = {
          keyword: $(e.target).val()
        }
        this.getRequests(params, (requests) => {
          this.modalForm.requests = requests
        })
      },
      // モーダル内Requestの並び替え完了
      onSortedSelectModalRequest() {
        let index = this.editingContentsIndex
        let content = this.contents[index]
        content.requests = this.modalForm.selectedRequests
        this.$set(this.contents, index, content)
        this.getRequestViews(index, content.requests.map(request => request.id))
      },
      // Requestの一覧を取得する
      getRequests(params, callback) {
        this.isModalRequestSeraching = true
        axios
          .get('/api/v1/requests', {
            params: params
          })
          .then(resp => {
            this.isModalRequestSeraching = false
            callback(resp.data.requests)
          })
      },
      // RequestListのViewをセットする
      getRequestViews(index, request_ids) {
        let content = this.contents[index]
        content.request_list_html = ''
        this.$set(this.contents, index, content)
        axios({
            method: 'GET',
            url: '/owner/specials/request_list',
            params: { request_ids: request_ids },
            responseType : 'document',
          })
          .then(resp => {
            content.request_list_html += $(resp.data.body).html()
            this.$set(this.contents, index, content)
          })
      },
      dispRequestListAddable(request) {
        return this.modalForm.selectedRequests.findIndex(it => it.id === request.id) >= 0
      },
      // 表示用
      dispBackgroundImage() {
        if (!this.special.background_image_file) {
          return
        } else if (!!this.special.background_image_file.url && !this.special.background_image_file.url.endsWith("special/") ) {
          return this.special.background_image_file.url
        } else if (this.isString(this.special.background_image_file)) {
          return this.special.background_image_file
        } else if ($(id).data('default-background-image')) {
          return $(id).data('default-background-image').url
        } else {
          return
        }
      },
      dispTextColor() {
        if (this.special.text_color_type == 'white') {
          return 'text-white'
        } else {
          return 'text-dark'
        }
      },
      dispItemListAddable(item) {
        return this.modalForm.selectedItems.findIndex(it => it.id === item.id) >= 0
      },
      // byteを表示用の変換
      formatBytes(bytes, decimals = 1)  {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      },
      isString(value) {
        if (typeof value === "string" || value instanceof String) {
          return true;
        } else {
          return false;
        }
      },
      // アップロードした画像を表示
      previewImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.special.background_image_file = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      // Itemの一覧を取得する
      getItems(params, callback) {
        this.isModalItemSeraching = true
        axios
          .get('/api/v1/items', {
            params: params
          })
          .then(resp => {
            this.isModalItemSeraching = false
            callback(resp.data.items)
          })
      },
      // ItemListのViewをセットする
      getItemViews(index, item_ids) {
        let content = this.contents[index]
        content.item_list_html = ''
        this.$set(this.contents, index, content)
        axios({
            method: 'GET',
            url: '/owner/specials/item_list',
            params: { item_ids: item_ids },
            responseType : 'document',
          })
          .then(resp => {
            content.item_list_html += $(resp.data.body).html()
            this.$set(this.contents, index, content)
          })
      },
      // 画像をBase64化
      getBase64 (file, maxWidth, maxHeight) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = (e) => {
            const img = new Image()
            img.src = e.target.result
            img.onload = () => {
              let width = img.width
              let height = img.height

              if (maxWidth && width > maxWidth) {
                height = Math.round(height * maxWidth / width)
                width = maxWidth
              }
              if (maxHeight && height > maxHeight) {
                width = Math.round(width * maxHeight / height)
                height = maxHeight
              }

              const canvas = document.createElement('canvas');
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, width, height);
              const base64File = canvas.toDataURL(file.type)
              resolve(base64File)
            }
            img.onerror = error => reject(error)
          }
          reader.onerror = error => reject(error)
        })
      },
      // コンテンツの初期化
      setupSpecialContents() {
        this.special.special_buttons.map((obj) => {
          obj.type = 'button'
          this.contents.push(obj)
        })
        this.special.special_headings.map((obj) => {
          obj.type = 'heading'
          this.contents.push(obj)
        })
        this.special.special_htmls.map((obj) => {
          obj.type = 'html'
          this.contents.push(obj)
        })
        this.special.special_images.map((obj) => {
          obj.type = 'image'
          obj.image_source = obj.image_file.url
          this.contents.push(obj)
        })
        this.special.special_sentences.map((obj) => {
          obj.type = 'sentence'
          this.contents.push(obj)
        })
        this.special.special_item_lists.map((obj) => {
          obj.type = 'item_list'
          obj.items = []
          this.contents.push(obj)
        })
        this.special.special_request_lists.map((obj) => {
          obj.type = 'request_list'
          obj.requests = []
          this.contents.push(obj)
        })
        this.contents.sort(function(a, b) {
          if (a.sort < b.sort) {
            return -1
          } else if (a.sort > b.sort) {
            return 1
          } else {
            return 0
          }
        })

        this.contents.map((obj, index) => {
          // item_listのitem_idsをフェッチする
          if (obj.type == 'item_list' && obj.item_ids != null && obj.item_ids != '') {
            let item_ids = JSON.parse(obj.item_ids)
            if (item_ids.length > 0) {
              // リストの取得
              this.getItems({
                item_ids: item_ids
              }, (items) => {
                obj.items = items
                this.$set(this.contents, index, obj)
              })
              // Viewの取得
              this.getItemViews(index, item_ids)
            }
          }
          // request_listのrequest_idsをフェッチする
          if (obj.type == 'request_list' && obj.request_ids != null && obj.request_ids != '') {
            let request_ids = JSON.parse(obj.request_ids)
            if (request_ids.length > 0) {
              // リストの取得
              this.getRequests({
                request_ids: request_ids
              }, (requests) => {
                obj.requests = requests
                this.$set(this.contents, index, obj)
              })
              // Viewの取得
              this.getRequestViews(index, request_ids)
            }
          }
        })
      }
    },
  })

  // ************************************************
  //
  //  Metaタグ
  //
  // ************************************************
  // モーダル表示
  $('#js-link-setting-special-meta-tag').on('click', (e) => {
    e.preventDefault();
    const metaTitle = $('#special_meta_title').val()
    $('#js-special-meta-title').val(metaTitle)
    const metaDescription = $('#special_meta_description').val()
    $('#js-special-meta-description').val(metaDescription)
    $('#special_is_meta_edited').val('1')
    $('#js-special-meta-modal').modal('show')
  })
  // キャンセルボタン
  $('#js-special-meta-cancel-btn').on('click', (e) => {
    e.preventDefault();
    $('#js-special-meta-title').val('')
    $('#js-special-meta-description').val('')
    $('#js-special-meta-modal').modal('hide')
  })
  // 確定ボタン
  $('#js-special-meta-fix-btn').on('click', (e) => {
    e.preventDefault();
    const metaTitle = $('#js-special-meta-title').val()
    $('#special_meta_title').val(metaTitle)
    const metaDescription = $('#js-special-meta-description').val()
    $('#special_meta_description').val(metaDescription)
    $('#js-special-meta-modal').modal('hide')
  })

})
