import Vue from 'vue'
import moment from 'moment'
import axios from 'axios'

$(() => {
  if (!$('.js-schedule-calendar').length) {
    return
  }

  const setupComponent = (id, element) => {
   
    new Vue({
      el: id,
      data: {
        type: $(id).data('type'), // link, from_to_hour, option, date
        inputId: $(id).data('input-id'),
        labelId: $(id).data('label-id'),
        itemId: $(id).data('item-id'),
        today: null,
        currentMonth: null,
        currentCalndar: [],
        availableDates: {},
        availableTimes: [],
        selectDate: null,
        // type == selct_from_to
        selectFrom: null,
        selectFromOptions: [],
        selectTo: null,
        selectToOptions: [],
      },
      created() {
        // 今月を設定
        let now = moment(new Date())
        this.now = now
        this.today = this.formatDate(now.year(), now.month(), now.date())
        let defaultDate = $('#' + this.inputId + '-from-dt').val()
        if (!!defaultDate) {
          this.currentMonth = moment(defaultDate)
          this.currentCalndar = this.getCalendar(this.currentMonth)
          this.refreshAvailableDates(() => {
            // 選択までのアクションを再現
            this.onSelectDate(this.currentMonth.format('YYYYMMDD'))
            // 微妙だがCallbackさせるのが手間なので
            setTimeout(() => {
              this.selectFrom = $('#' + this.inputId + '-from-time').val().replace(":", "")
              if (!this.selectFrom) {
                this.selectFrom = this.selectFromOptions[0]
              }
              this.onChangeSelectFrom()

              if (this.type == 'from_to_hour') {
                this.selectTo = $('#' + this.inputId + '-to-time').val().replace(":", "")
                if (!this.selectTo) {
                  this.selectTo = this.selectToOptions[0]
                }
                this.onSubmitFromToHour()
              } else if (this.type == 'from_only') {
                this.onSubmitFromOnly()
              } else if (this.type == 'date') {
                this.onSubmitDate()
              }
            }, 500)
          })
        } else {
          this.currentMonth = moment(new Date())
          this.currentCalndar = this.getCalendar(this.currentMonth)
          this.refreshAvailableDates()
        }
      },
      methods: {
        dispDate(val) {
          if (!val) {
            return null
          }
          return val % 100
        },
        dispTime(time) {
          return time.slice(0, 2) + ":" + time.slice(2);
        },
        dispClass(momentObj, val) {
          if (!val) {
            return 'blank'
          }
          if (this.selectDate == val) {
            return "selected"
          }
          if (this.today == val) {
            return "today"
          }
          let currentVal = this.formatDate(momentObj.year(), momentObj.month(), momentObj.date())
          if (parseInt(currentVal / 100) != parseInt(val / 100)) {
            return 'text-muted'
          }
        },
        dispStatusClass(date) {
          if (!date) {
            return 
          }

          if (date < this.today) {
            return 'past' 
          }

          if (!this.availableDates[this.itemId]) {
            return
          }

          let wday =  new Date(
              date.toString().slice(0, 4) + "-" + date.toString().slice(4, 6) + "-" + date.toString().slice(6, 8)
            ).getDay()
          let availableDates = this.availableDates[this.itemId]

          if (availableDates[date] === '') {
            return 'blank' 
          } else if (availableDates[date] != undefined) {
            return 'working'
          } else if (availableDates[wday] === '') {
            return 'blank'
          } else if (availableDates[wday] != undefined) {
            return 'working'
          } else {
            return 'blank'
          }
        },
        dispAvailableTimeClass(index) {
          if (!this.availableTimes[index]) {
            return "bg-gray-200"
          }

          let time = this.availableTimeIndexToTime(index)
          if (this.selectFrom && !this.selectTo) {
            if (this.selectFrom == time) {
              return "mc-bg-primary"
            }
          } else if (this.selectFrom && this.selectTo) {
            if (this.selectFrom <= time && time < this.selectTo) {
              return "mc-bg-primary"
            }
          }
        },
        dispSelectDate() {
          if (!this.selectDate) {
            return
          }
          return this.selectDate.toString().slice(4, 6) + "/" + this.selectDate.toString().slice(6, 8)
        },
        dispSelectFromOnly() {
          let date = this.selectDate.toString().slice(0, 4) + "/" + this.selectDate.toString().slice(4, 6) + "/" + this.selectDate.toString().slice(6, 8)

          return date + " " + this.dispTime(this.selectFrom)
        },
        dispSelectFromTo() {
          let diff = parseInt(this.selectTo) - parseInt(this.selectFrom)
          let hour = Math.floor(diff / 100)
          let minute = (diff % 100)

          let date = this.selectDate.toString().slice(0, 4) + "/" + this.selectDate.toString().slice(4, 6) + "/" + this.selectDate.toString().slice(6, 8)

          return date + " " + this.dispTime(this.selectFrom) + " 〜 " + this.dispTime(this.selectTo) + "（" + hour + "時間" + ((minute > 0) ? "30分" : '') + "）"
        },
        formatDate(year, month, date) {
          return year * 10000 + (month + 1) * 100 + date
        },
        getCalendar(momentObj)  {
          // 当月
          let now = momentObj.clone();
          let startDate = now.startOf('month').date()
          let startDay = now.startOf('month').day()
          let endDate = now.endOf('month').date()
          let endDay = now.endOf('month').day()
          let calendar = []
          for (let i = startDate; i <= endDate; i++) {
            calendar.push(this.formatDate(now.year(), now.month(), i))
          }
          // 前月
          let prevMonth = now.clone().subtract(1, 'months')
          let prevEndDate = prevMonth.endOf('month').date()
          if (startDay == 0) {
            // 日曜始まり
            for (let i = 0; i < 6; i ++) {
              // 日付ではなく空を入れる
              calendar.unshift(null)
            }
          } else {
            for (let i = 0; i < (startDay - 1); i++) {
              // 日付ではなく空を入れる
              calendar.unshift(null)
            }
          }
          // 翌月
          let nextMonth = now.clone().add(1, 'months')
          if (endDay == 0) {
            // 追加なし
          } else {
            for (let i = 0; i < (7 - endDay); i++) {
              // 日付ではなく空を入れる
              calendar.push(null)
            }
          }

          return calendar
        },
        refreshAvailableDates(callback) {
          this.availableDates = []

          if ((this.currentMonth.year() * 100 + this.currentMonth.month()) < (this.now.year() * 100 + this.now.month())) {
            return
          }
          let that = this
          axios
            .get('/api/v1/schedules/dates', {
              params: {
                month: this.currentMonth,
                item_id: this.itemId,
              }
            })
            .then(resp => {
              that.availableDates = resp.data.available_dates
              callback && callback()
            })
        },
        refreshAvailableDatesTimes() {
          this.availableTimes = []
          this.selectFrom = null
          this.selectTo = null

          let that = this
          axios
            .get('/api/v1/schedules/times', {
              params: {
                date: this.selectDate,
                item_id: this.itemId,
              }
            })
            .then(resp => {
              // 30分毎の24時間分のリストを作成
              for (let i = 0; i < 48; i++) {
                let time = this.availableTimeIndexToTime(i)
                if (resp.data.available_times.find((t) => t === time)) {
                  that.availableTimes.push(true)
                } else {
                  that.availableTimes.push(false)
                }
              }
              that.refreshSelectFromOptions()
            })
        },
        refreshSelectFromOptions() {
          this.selectFrom = null
          this.selectTo = null
          this.selectFromOptions = []
          this.selectToOptions = []
          for (let i = 0; i < this.availableTimes.length; i++) {
            if (this.availableTimes[i]) {
              let time = this.availableTimeIndexToTime(i)
              this.selectFromOptions.push(time)
            }
          }
        },
        refreshSelectToOptions() {
          this.selectTo = null
          this.selectToOptions = []
          for (let i = 0; i < this.availableTimes.length; i++) {
            let time = this.availableTimeIndexToTime(i)
            if (this.selectFrom >= time) {
              continue
            }
            if (this.availableTimes[i]) {
              this.selectToOptions.push(time)
            } else {
              this.selectToOptions.push(time)
              return
            }
          }
        },
        availableTimeIndexToTime(index) {
          let hourIndex =  Math.floor(index / 2)
          let minuteIndex = index % 2
          return ('00' + hourIndex).slice(-2) + (minuteIndex == 0 ? '00' : '30') 
        },
        onSelectDate(val) {
          if (this.selectDate === val) {
            return
          }
          let clazz = this.dispStatusClass(val)
          if (clazz !== 'working') {
            return
          }
          this.selectDate = val
          this.refreshAvailableDatesTimes()
        },
        onChangeSelectFrom() {
          this.refreshSelectToOptions()
        },
        onChangeSelectTo() {

        },
        onPrevMonth() {
          this.currentMonth.subtract(1, 'months')
          this.currentCalndar = this.getCalendar(this.currentMonth)
          this.refreshAvailableDates()
        },
        onNextMonth() {
          this.currentMonth.add(1, 'months')
          this.currentCalndar = this.getCalendar(this.currentMonth)
          this.refreshAvailableDates()
        },
        onSubmitFromOnly() {
          $('#' + this.labelId).val(this.dispSelectFromOnly())

          let inputDate = this.selectDate.toString().slice(0, 4) + "-" + this.selectDate.toString().slice(4, 6) + "-" + this.selectDate.toString().slice(6, 8)

          $('#' + this.inputId + '-from-dt').val(inputDate)
          $('#' + this.inputId + '-from-time').val(this.dispTime(this.selectFrom))

          $('.js-schedule-calendar_wrapper').addClass('d-none')
        },
        onSubmitDate() {
          $('#' + this.labelId).val(this.dispSelectDate())

          let inputDate = this.selectDate.toString().slice(0, 4) + "-" + this.selectDate.toString().slice(4, 6) + "-" + this.selectDate.toString().slice(6, 8)

          $('#' + this.inputId + '-from-dt').val(inputDate)

          $('.js-schedule-calendar_wrapper').addClass('d-none')
        },
        onSubmitFromToHour() {
          $('#' + this.labelId).val(this.dispSelectFromTo())

          let inputDate = this.selectDate.toString().slice(0, 4) + "-" + this.selectDate.toString().slice(4, 6) + "-" + this.selectDate.toString().slice(6, 8)

          $('#' + this.inputId + '-from-dt').val(inputDate)
          $('#' + this.inputId + '-from-time').val(this.dispTime(this.selectFrom))
          $('#' + this.inputId + '-to-dt').val(inputDate)
          $('#' + this.inputId + '-to-time').val(this.dispTime(this.selectTo))

          $('.js-schedule-calendar_wrapper').addClass('d-none')
        }
      },
    })
  }

  $(document).on('click', '.js-schedule-calendar_close', function (e) {
      $('.js-schedule-calendar').addClass('d-none')
  })

  $('.js-schedule-calendar').each((i, element) => {
    let id = "#" + $(element).attr('id')
    setupComponent(id, element)
  })

  // picker以外をタップしたら閉じる
  $(document).on('click', '.js-schedule-calendar_wrapper', function (e) {
    if(!$(e.target).closest('.js-schedule-calendar').length) {
      $('.js-schedule-calendar_wrapper').addClass('d-none')
    }
  })
  $(document).on('click', '.js-schedule-calendar_close', function (e) {
      $('.js-schedule-calendar_wrapper').addClass('d-none')
  })

  $('.js-schedule-calendar_trigger').each((i, element) => {
    let id = $(element).attr('id')
    let wrapper = $("#" + id + '-wrapper')
    let calendar = $("#" + id + '-wrapper .js-schedule-calendar')

    // pickerのポジション
    let onChangeModalPosition = () => {
      let offset = $(element).offset()
      let height = $(element).outerHeight()
      calendar.offset(() => {
        return {
          top: offset.top + height + 8,
          left: offset.left,
        };
      });
    }
    $(window).scroll(function() {
      onChangeModalPosition()
    });
    $(window).resize(() => {
      onChangeModalPosition()
    });
    onChangeModalPosition()

    // Inputタップでpicker開く
    $(element).on('click', (e) => {
      // pickerを表示
      wrapper.removeClass('d-none')
      // pickerの位置を調整
      onChangeModalPosition()
      // 画面をスクロール
      const offset = 70;
      const position = $(e.target).offset().top;
      const speed = 200;
      $("html,body").animate({ scrollTop: position - offset}, speed);
    })
  })
})
