$(() => {
  if (!$('#mypage-orders_show').length) {
    return
  }

  $('#js-order-reserve-form select, #js-order-reserve-form textarea').on('change', () => {
    $('button').attr('disabled', false)
  })

})
