$(() => {
  if (!$('.js-image-file-input').length) {
    return
  }

  $('.js-image-file-input_input').on('change',  (e) => {
    const parent = $(e.target).closest('.js-image-file-input')
    const preview = parent.find('.js-image-file-input_preview')
    const previewImage = parent.find('img')
    const paramsInput = parent.find('.js-image-file-input_params')
    const removeInput = parent.find('.js-image-file-input_remove')

    const reader = new FileReader();
    reader.onload = (e) => {
      // プレビューをセット
      preview.show()
      previewImage.attr('src', e.target.result)
      // リクエストパラメータをセット
      paramsInput.val(e.target.result)
      // 削除パラメータをリセット
      removeInput.val('0')

      // バリデーションを発火
      paramsInput.removeClass('js-validate-skip')
      paramsInput.trigger('change')
    }
    reader.readAsDataURL(e.target.files[0]);

  });

  $('.js-image-file-input_preview_image-delete').on('click', (e) => {
    const parent = $(e.currentTarget).closest('.js-image-file-input')
    const preview = parent.find('.js-image-file-input_preview')
    const previewImage = parent.find('img')
    const paramsInput = parent.find('.js-image-file-input_params')
    const removeInput = parent.find('.js-image-file-input_remove')

    // プレビューをリセット
    preview.hide()
    previewImage.attr('src', e.target.result)
    // プレビューをリセット
    paramsInput.val(null)
    // 削除パラメータをセット
    removeInput.val('1')

    // バリデーションを発火
    paramsInput.removeClass('js-validate-skip')
    paramsInput.trigger('change')
  })
})
