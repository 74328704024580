import Vue from 'vue'

$(() => {
  if (!$('.js-image-file-input-by-url').length) {
    return
  }

  const setupComponent = (id) => {
    new Vue({
      el: id,
      data: {
        rules: $(id).data('rules'), // Validルール
        url: $(id).data('url'),
        errors: [], // Validエラーメッセージ
      },
      mounted() {
        $('.js-form-validation .submit').on('click', (e) => {
          this.validForm()
        })
      },
      methods: {
        validForm() {
          if (!this.rules || this.rules.length == 0) {
            // 未定義はリターン
            return
          }
          this.errors = []
          if (!this.url || this.url == '') {
            this.errors.push("ファイルを選択してください")
          }

          $(id).find('input').trigger('change')
        },
        selectedFile(e) {
          e.preventDefault()
          let that = this
          let formData = new FormData()
          formData.append('image[image_file]', e.target.files[0])
          $.ajax({
            url: '/api/v1/images',
            type: 'POST',
            data: formData,
            dataType: 'json',
            processData: false,
            contentType: false,
          }).done((data) => {
            that.url = data.image_file.url
            this.validForm()
          })
        },
        deleteUrl() {
          this.url = ""
          this.validForm()
        },
      },
    })
  }

  $('.js-image-file-input-by-url').each((i, element) => {
    const id = $(element).attr('id')
    setupComponent('#' + id)
  })
})
