$(() => {
  if (!$('.js-month-input').length) {
    return
  }


  // 値を隠しフォームに反映する
  let copyValue = (input) => {
    let parent = input.parent()
    if (!input.val() || input === "") {
      return
    }
    parent.find(".js-month-input_output").val(input.val() + '-01')
  }

  // 隠しフォームをセットする
  let setHiddenForm = (input) => {
    let name = input.attr('name')
    input.attr('name', '')
    input.after(`<input type="hidden" name="${name}" class="js-month-input_output"></input>`)
  }

  // event
  $('.js-month-input').on('change', (e) => {
    copyValue($(e.target))
  })

  // 初期処理
  $('.js-month-input').each((i, elm) => {
    setHiddenForm($(elm))
    copyValue($(elm))
  })
})
