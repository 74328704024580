import Vue from 'vue'

$(() => {
  if (!$('#items_show').length) {
    return
  }

  // Slicker
  if ($('.js-thumbnail-slicker').length > 0) {
    let slicker = $('.js-thumbnail-slicker').slick({
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 7000,
      fade: true,
      cssEase: 'linear'
    })
  }
  if ($('.js-slicker').length > 0) {
    let slicker = $('.js-slicker').slick({
      arrows: false,
      fade: true,
    })
    $('.js-slicker-nav-item').on('mouseenter', (e) => {
      $('.js-slicker-nav-item').removeClass('active')
      self = $(e.target) 
      self.addClass('active')
      $('.js-slicker ').slick('slickGoTo', self.data('index'))
    })
  }

  // Option
  if ($('#js-order-form').length > 0) {
    const id = '#js-order-form'
    new Vue({
      el: id,
      data: {
        item: $(id).data('item'),
        options: $(id).data('options'),
        selectOptions: [],
      },
      methods: {
        viewTotalPrice() {
          let totalPrice = 0
          totalPrice += this.item.price
          this.selectOptions.map((o) => {
            totalPrice += o.price
          })
          return totalPrice.toLocaleString()
        },
        viewOrderHref() {
          let href = `/orders/new?item_id=${this.item.id}`
          let options = ""
          this.selectOptions.map((o, index) => {
            options += `&option_id[]=${o.id}`
          })
          return href + options
        },
        dispChecked(id) {
          this.selectOptions.findIndex((o) => o.id === id) > 0
        },
        onTapOption(id) {
          let index = this.selectOptions.findIndex((o) => o.id === id)
          if (-1 < index) {
            this.selectOptions.splice(index, 1)
          } else {
            let option = this.options.find((o) => o.id == id)
            this.selectOptions.push(option)
          }
        }
      },
    })
  }
})
