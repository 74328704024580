$(() => {
  if (!$('.js-list-search-modal').length) {
    return
  }

  $('.js-list-search-modal_trigger').on('click',  (e) => {
    $('.js-list-search-modal').addClass('d-block')
    $('body').addClass('list-search-modal_open')
  })

  $('.js-list-search-modal_close').on('click', (e) => {
    $('.js-list-search-modal').removeClass('d-block')
    $('body').removeClass('list-search-modal_open')
  })
 
})
