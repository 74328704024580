import lottie from 'lottie-web'

$(() => {
  if ($('#demos_new').length > 0) {
    lottie.loadAnimation({
      container: document.getElementById('processing-icon'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/demo/processing.json'
    });

    $('.submit').on('click', () => {
      $('#demo-processing').removeClass('d-none')
    })
  }

  if ($('#demos_complete').length > 0) {
    lottie.loadAnimation({
      container: document.getElementById('complete-icon'),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: '/demo/complete.json'
    });
  }

  
 
})
