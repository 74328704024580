$(() => {
  if (!$('#owner-transfer_summaries_show').length) {
    return
  }

  $('.js-status-type').on('change', (e) => {
    if ($(e.target).hasClass('js-status-type-reject')) {
      // 非承認
      $(e.target).closest('td').find('.js-reject-reason-type').removeClass('d-none')
    } else {
      // 非承認以外
      $(e.target).closest('td').find('.js-reject-reason-type').addClass('d-none')
    }
  })

  $('.js-search-status-type').on('change', (e) => {
    $(e.target).closest('form').submit()
  })
})