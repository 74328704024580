$(() => {
  if (!$('#owner-manual_transfers_index').length) {
    return
  }

  // 非承認理由を選択
  $("select.js-reject-reason-type-select").on('change', (e) => {
    let select = $(e.target) 
    let form = select.closest('form')
    let formGroup = form.find('.js-reject-reason-textarea')
    let textarea = formGroup.find('textarea')
    let submit = form.find('.submit')
    if (select.val() == 'other') {
      formGroup.show()
      formGroup.removeClass('js-validate-skip')
    } else {
      formGroup.hide()
      formGroup.addClass('js-validate-skip')
      formGroup.removeClass('is-invalid')
      textarea.removeClass('is-invalid')
      formGroup.find('.invalid-feedback').remove()
      submit.removeAttr('disabled')
      submit.removeClass('disabled')
    }
  })

  // submitを表示
  let toggleSubmitForm = () => {
    if (!!$(".js-transfer-select:checked").length) {
      $(".db-fixed-submit-wrapper").show()
    } else {
      $(".db-fixed-submit-wrapper").hide()
    }
  }

  // csvダウンロードでモーダルを閉じる
  $('#csv-download-modal button').on('click', (e) => {
    if (!$('input#transfer_dt').val()) {
      e.preventDefault();
      e.stopPropagation();
      alert('振込実行日を選択してください')
      return
    }

    $('#csv-download-modal').modal('hide')
  })


  // 全選択
  $("#js-transfer-all-select").on('change', () => {
    let checked = $('input#js-transfer-all-select').prop("checked")
    $(".js-transfer-select").prop("checked", checked)
    toggleSubmitForm()
  }) 

  // checkを切り替え
  $(".js-transfer-select").on('change', () => {
    toggleSubmitForm()
  })

  // ステータス変更でリロード
  $('.js-search-status-type').on('change', (e) => {
    $(e.target).closest('form').submit()
  })
})