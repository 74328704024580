$(() => {
  if (!$('.js-information-last-readed').length) {
    return
  }

  // お知らせ開く
  $('.js-information-last-readed').on('click', () => {
    $.ajax({
      url: `/api/v1/information_last_readeds`,
      type: 'POST',
      dataType: 'json',
      processData: false,
      contentType: false,
    }).done((data) => {
      $('.js-information-last-readed').find('.badge-counter').remove()
      $('.js-information-last-readed').removeClass('js-information-last-readed') 
    })
  })
})
